//@ts-ignore
import seedrandom from 'seedrandom'

const colorTable = {
  0: '264653',
  1: '2A9D8F',
  2: 'E9C46A',
  3: 'F4A261',
  4: 'E76F51',
  5: '8ECAE6',
  6: '219EBC',
  7: '023047',
  8: 'FFB703',
  9: 'FB8500'
}

export const getUserColor = (name: string) => {
  const generator = seedrandom(name);
  const rndValue = Math.floor(generator() * 9) + 0;
  const userColor = (colorTable as any)[rndValue] as string;
  return `#${userColor}`;
}