import { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { Button, Card, Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
// @ts-ignore
import { isFlashCardQuestion, isMultipleChoiceQuestion, isTextQuestion, PublicBrowserType, Question, Quiz, QuizAccessibilityLevel, QuizControlFlowLevel, QuizMeta, QuizResultsDisplayMode, ServerQuizLight, UserAnswer, UserAnswerMultipleChoice } from '../quiz-types';
import { getUserColor } from '../user';
import { MultipleChoiceQuestionForm } from '../TakeQuiz/MultipleChoiceQuestionForm';
import { QuizResults } from '../TakeQuiz/QuizResults';
import { useRecoilState } from 'recoil';
import { activeQuiz, activeQuizMeta, currentQuestionState, currentUserAnswersState, userAccessTokenState, userCompleteQuizState, userProfileState, userStartQuizState } from '../state/mainstate';
import { PublicBrowser } from '../Browser/PublicBrowser';
import { JoinByCode } from '../Modals/JoinByCode';
import { useNavigate } from 'react-router';
import { getAPIPath } from '../core';
import { toast } from 'react-toastify';
import { MainTheme } from '../MainTheme';
import { useAuth0 } from '@auth0/auth0-react';
import { ShareQuiz } from '../Modals/ShareQuiz';
import { LoginModal } from '../LoginModal';
import { SetupProfile } from '../Modals/SetupProfile';
import { EnterInviteCode } from '../Modals/EnterInviteCode';
import { SetupSubscription } from '../Modals/SetupSubscription';


interface Props {
  joinByCode?: boolean
}

export const TakeQuizBrowser = (props: Props) => {

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const navigate = useNavigate();


  // Recoil State
  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);
  const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState)
  const [userAnswers, setUserAnswers] = useRecoilState(currentUserAnswersState)
  const [userStartQuiz, setUserStartQuiz] = useRecoilState(userStartQuizState)
  const [userCompleteQuiz, setUserCompleteQuiz] = useRecoilState(userCompleteQuizState)
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  // Local state
  const [timerStarted, setTimerStarted] = useState(false);
  const [timerTimedOut, setTimerTimedOut] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const timerValueRef = useRef(timerValue);
  const currentQuestionRef = useRef(currentQuestion);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [testsYours, setTestsYours] = useState<ServerQuizLight[]>([]);
  const [testsYoursLiked, setTestsYoursLiked] = useState<ServerQuizLight[]>([]);
  const [testsShared, setTestsShared] = useState<ServerQuizLight[]>([]);


  // Refs to handle stale state
  const timerRef = useRef<any>();

  useEffect(() => {
    timerValueRef.current = timerValue;
    currentQuestionRef.current = currentQuestion;
  }, [timerValue, currentQuestion]);

  useEffect(() => {
    loadQuizes();
  }, []);


  const createQuiz = () => {
    const newQuiz: Quiz = {
      title: '',
      description: '',
      defaultTimeLimit: 0,
      overallTimeLimit: 0,
      questions: []
    };
    const newMeta: QuizMeta = {
      category_parent_id: null,
      category_sub_id: null,
      accessibility: QuizAccessibilityLevel.Public,
      control_flow: QuizControlFlowLevel.StrictChronological,
      results_display_mode: QuizResultsDisplayMode.Immediately
    }
    setQuiz(newQuiz);
    setQuizMeta(newMeta);
  }

  const loadQuizes = async () => {
    // Check that the profile is completely setup.
    setIsLoading(true);

    const quizRespMe = fetch(getAPIPath() + '/api/quiz/quiz/me', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    const quizRespSubs = fetch(getAPIPath() + '/api/quiz/quiz/me/subscriptions', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });
    // TODO: Public later

    const [quizRespMeDone, quizRespSubsDone] = await Promise.all([quizRespMe, quizRespSubs]);

    if (quizRespMeDone.status !== 200 || quizRespSubsDone.status !== 200) {
      toast.error("Failed to load quizes");
      return;
    }

    const quizListMe = await quizRespMeDone.json();
    const quizListSubs = await quizRespSubsDone.json();
    setTestsYours(quizListMe);
    setTestsYoursLiked(quizListSubs);
    setIsLoading(false);
    setIsLoaded(true);
  }


  const addTimeoutAnswer = () => {
    if (quiz === null) return;
    if (quiz.questions[currentQuestionRef.current] === undefined) return; // this can occur when 'finishing' the quiz 
    // i.e. when currentQuestion > question arr length (when finished). E.g. when queued execution occurs in the same ms as 
    // user clicking complete

    const userAnswersCopy = JSON.parse(JSON.stringify(userAnswers));
    const timeoutAnsw: UserAnswerMultipleChoice = {
      questionId: quiz.questions[currentQuestionRef.current].id,
      answerId: [],
      timeout: true
    };
    userAnswersCopy.push(timeoutAnsw);

    setUserAnswers(userAnswersCopy);
    console.log('addTimeoutAnswer', userAnswersCopy);
  }

  const resetTimer = () => {
    clearInterval(timerRef.current);
    setTimerStarted(false);
    setTimerValue(0);
  }




  return <>
    {isAuthenticated && !!props.joinByCode && <JoinByCode />}
    {isAuthenticated && <ShareQuiz />}
    {isAuthenticated && userProfile !== null && userProfile.user.activated === false && <EnterInviteCode />}
    {isAuthenticated && userProfile !== null && userProfile.user.activated && userProfile.user.profile_setup === false && <SetupProfile />}
    {isAuthenticated && userProfile !== null && userProfile.user.activated && userProfile.user.profile_setup && userProfile.user.flg_subscription_active === false && <SetupSubscription />}

    <Card>
      <Card.Title></Card.Title>
      <Card.Body>


        <div style={{ textAlign: 'center' }}>
          <Button onClick={() => {
            navigate('/join')
          }} style={{ marginRight: 10 }}>
            <i className="bi bi-plus"></i> Join by code</Button>
          <Button onClick={() => {
            navigate('/create')
          }} style={{ marginRight: 10 }}>
            <i className="bi bi-plus"></i> Create test</Button>
        </div>


        {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>}


        <h3>Your tests</h3>
        {!isLoading && isLoaded && <div>
          <hr />
          <PublicBrowser
            quizes={testsYours}
            loadQuizes={loadQuizes}
            type={PublicBrowserType.Mine}
          />
          <h3>Your subscriptions  </h3>
          <hr />
          <PublicBrowser
            quizes={testsYoursLiked}
            loadQuizes={loadQuizes}
            type={PublicBrowserType.Subscribed}
          />
          <h3>Public </h3>
          <hr />
          <PublicBrowser
            quizes={testsShared}
            loadQuizes={loadQuizes}
            type={PublicBrowserType.Public}
          />
        </div>}



      </Card.Body></Card>


  </>;

}