import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { getAPIPath } from "./core";
import { EditQuiz } from "./EditQuiz/EditQuiz";
import { EditMode, Quiz, QuizMeta } from "./quiz-types";
import { activeQuiz, activeQuizMeta, editQuizModeState, userAccessTokenState, userProfileState } from "./state/mainstate";

export const EditQuizMother = () => {

  const sharecode = useParams().code;

  const navigate = useNavigate();

  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);

  const [editMode, setEditMode] = useRecoilState(editQuizModeState);
  const userAccessToken = useRecoilValue(userAccessTokenState);

  const userProfile = useRecoilValue(userProfileState);


  const updateQuiz = async (redirect: boolean, quizPayload?: Quiz, metaPayload?: QuizMeta) => {

    const quizToUse = quizPayload ?? quiz;
    const metaToUse = metaPayload ?? quizMeta;
    if (quizToUse === null || metaToUse === null) return;


    const { title } = quizToUse;
    const { category_parent_id, category_sub_id, accessibility, control_flow, results_display_mode } = metaToUse;

    const response = await fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}`, {
      method: "PUT",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userAccessToken}`
      },
      body: JSON.stringify({
        name: title,
        category_parent_id,
        category_sub_id,
        accessibility,
        control_flow,
        results_display_mode,
        quiz: quizToUse
      })
    });

    // Handle errors with code 400 (invalid input)
    if (response.status === 400) {
      const data = await response.json();
      if (data.code !== undefined && data.code === 'private_quiz_limit_met') {
        toast.error("You have reached the limit of private quizes. Please upgrade your account to create more or make it public.", {
          autoClose: 10000,
          onClick: (e) => {
            const confirmRes = window.confirm("Changes might not be saved. Do you want to continue?");
            navigate(`/profile/${userProfile?.user.sharecode}/upgrade`);
            return false;
          }
        });
        return;
      } else {
        toast.error("There was an error updating the quiz. Please try again later.");
        return;
      }
    }
    // Fallback for everything else (non-200!)
    else if (response.status !== 200) { 
      toast.error("There was an error updating the quiz. Please try again later.");
      return;
    }
    const data = await response.json();

    toast.success("Changes saved.");

    if (redirect) {
      setEditMode(null);
      navigate(`/quiz/${sharecode}`)
    }
  }

  useEffect(() => {

    const loadQuizData = async () => {

      const response = await fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userAccessToken}`
        }
      });

      if (response.status !== 200) {
        toast.error("Error loading quiz");
        return;
      }

      const data = await response.json();
      
      setQuiz(data.quiz.content);
      setQuizMeta({
        category_parent_id: data.quiz.category_parent_id,
        category_sub_id: data.quiz.category_sub_id,
        accessibility: data.quiz.accessibility,
        control_flow: data.quiz.control_flow,
        results_display_mode: data.quiz.results_display_mode
      })
      setEditMode(EditMode.Edit);

    }

    loadQuizData();

    // const newQuiz: Quiz = {
    //   title: '',
    //   description: '',
    //   defaultTimeLimit: 0,
    //   questions: []
    // };
    // setQuiz(newQuiz);
    // setEditMode(EditMode.Create);

  }, []);

  return <>
    <EditQuiz updateQuiz={updateQuiz} />
  </>;
};