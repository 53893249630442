import './App.css';
import 'katex/dist/katex.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { RecoilRoot } from 'recoil';
import { editorStats } from './shared';
import { Auth0Provider } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { MainRouter } from './MainRouter';
import { ThemeProvider } from 'react-bootstrap';


window.onload = function () {
  window.addEventListener("beforeunload", function (e) {

    console.log('editorStats.editing', editorStats.editing);
    if (!editorStats.editing) {
      return undefined;
    }

    var confirmationMessage = 'It looks like you have been editing something. '
      + 'If you leave before saving, your changes will be lost.';

    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  });
};



function App() {



  return (
    <>
      <RecoilRoot>
        <Auth0Provider
          domain="eduerity.eu.auth0.com"
          clientId="V5jifnvSJFYgdrfCwisWFbOeZGnVkiJ4"
          useRefreshTokens={false}
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: 'https://api.eduerity.com',
            scope: 'openid profile email',
          }}
        > 
          <MainRouter />
          <ToastContainer position="bottom-center" />
        </Auth0Provider>
      </RecoilRoot>
    </>
  );
}

export default App;
