import { countryList } from "../data";

export const validateUsername = (username: string): boolean => {
  const regex = /^[A-Za-z][A-Za-z0-9_ ]{3,20}[A-Za-z0-9]$/;
  const regexpMatch = username.match(regex);
  return regexpMatch !== null && regexpMatch.length > 0;
}

export const validateCountry = (countryCode: string): boolean => {
  return countryList.find(country => country.code === countryCode) !== undefined;
}