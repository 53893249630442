import { useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { Button, Card, Col, Pagination, ProgressBar, Row, Spinner } from 'react-bootstrap';
// @ts-ignore
import { isFlashCardQuestion, isMultipleChoiceQuestion, isTextQuestion, Question, Quiz, QuizControlFlowLevel, ServerQuizLight, UserAnswer, UserAnswerFlashCard, UserAnswerMultipleChoice, UserAnswerText } from '../quiz-types';
import { getUserColor } from '../user';
import { MultipleChoiceQuestionForm } from '../TakeQuiz/MultipleChoiceQuestionForm';
import { QuizResults } from '../TakeQuiz/QuizResults';
import { useRecoilState } from 'recoil';
import { activeQuiz, activeQuizMeta, currentQuestionState, currentUserAnswersState, editQuizModeState, userAccessTokenState, userCompleteQuizState, userStartQuizState } from '../state/mainstate';
import { PublicBrowser } from '../Browser/PublicBrowser';
import { JoinByCode } from '../Modals/JoinByCode';
import { useNavigate, useParams } from 'react-router';
import { getAPIPath } from '../core';
import { toast } from 'react-toastify';
import { TextQuestionForm } from './TextQuestionForm';
import { activeSeedState } from '../state/takequiz';
import { v4 as uuidv4 } from 'uuid';
import { calculateUserQuizResult } from './util';

import humanizeDuration from 'humanize-duration';
import { FlashCardQuestionForm } from './FlashCardQuestionForm';


export const TakeQuizActive = () => {

  const shareCode = useParams().code;

  const navigate = useNavigate();

  // Recoil State
  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);
  const [currentQuestion, setCurrentQuestion] = useRecoilState(currentQuestionState)
  const [userAnswers, setUserAnswers] = useRecoilState(currentUserAnswersState)
  const [userStartQuiz, setUserStartQuiz] = useRecoilState(userStartQuizState)
  const [userCompleteQuiz, setUserCompleteQuiz] = useRecoilState(userCompleteQuizState)
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);

  const [activeSeed, setActiveSeed] = useRecoilState(activeSeedState);

  // Local state
  const [timerStarted, setTimerStarted] = useState(false);
  const [timerTimedOut, setTimerTimedOut] = useState(false);
  const [timerValue, setTimerValue] = useState(0);

  const [globalTimerStarted, setGlobalTimerStarted] = useState(false);
  const [globalTimerTimedOut, setGlobalTimerTimedOut] = useState(false);
  const [globalTimerValue, setGlobalTimerValue] = useState(0);


  const timerValueRef = useRef(timerValue);
  const globalTimerValueRef = useRef(globalTimerValue);
  const currentQuestionRef = useRef(currentQuestion);
  const quizRef = useRef(quiz);
  const userAnswersRef = useRef(userAnswers);

  const [testsYours, setTestsYours] = useState<ServerQuizLight[]>([]);
  const [testsYoursLiked, setTestsYoursLiked] = useState<ServerQuizLight[]>([]);
  const [testsShared, setTestsShared] = useState<ServerQuizLight[]>([]);


  // Refs to handle stale state
  const timerRef = useRef<any>();
  const globalTimerRef = useRef<any>();

  useEffect(() => {
    timerValueRef.current = timerValue;
    globalTimerValueRef.current = globalTimerValue;
    currentQuestionRef.current = currentQuestion;
    quizRef.current = quiz;
    userAnswersRef.current = userAnswers;
  }, [timerValue, globalTimerValue, currentQuestion, quiz, userAnswers]);

  useEffect(() => {

    const loadQuiz = async () => {
      const response = await fetch(getAPIPath() + `/api/quiz/quiz/${shareCode}`, {
        headers: {
          'Authorization': `Bearer ${userAccessToken}`
        }
      });

      // Check if the endpoint answers with a quiz
      if (response.status !== 200) {
        toast.error('Quiz not found');
        navigate('/');
        return;
      }
      const data = await response.json();

      // Check that quiz is accessible
      if (data.length === 0) {
        toast.error('Quiz not found');
        navigate('/');
        return;
      }

      // Set quiz
      setQuiz(data.quiz.content);

      // Set quiz meta
      setQuizMeta({
        category_parent_id: data.quiz.category_parent_id,
        category_sub_id: data.quiz.category_sub_id,
        accessibility: data.quiz.accessibility,
        control_flow: data.quiz.control_flow,
        results_display_mode: data.quiz.results_display_mode,
      });

    }

    resetProgress();
    loadQuiz();

    // On unload
    return () => {
      resetProgress();
    }

  }, []);

  const submitUserResults = async (globalTimeout: boolean, userAnswersPayload ?: UserAnswer[]) => {
    if (quiz === null) return;

    const userAnswersToUse = userAnswersPayload ?? userAnswers;

    const quizResults = calculateUserQuizResult(quiz, userAnswersToUse);

    const { numCorrect, numAns, numAnsPct } = quizResults;

    const response = await fetch(getAPIPath() + `/api/quiz/quiz/${shareCode}/results`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userAccessToken}`
      },
      body: JSON.stringify({
        result: {
          globalTimeout,
          num_questions: numAns,
          num_correct: numCorrect,
          num_pct: Math.round(numAnsPct)
        }
      })
    });
    if (response.status !== 200) {
      toast.error("Error saving results");
      return;
    }

  };


  const addAnswer = (question: Question, answer: UserAnswer) => {
    const userAnswersCopy = JSON.parse(JSON.stringify(userAnswers));
    userAnswersCopy.push(answer);
    setUserAnswers(userAnswersCopy);
    resetTimer();
    console.log('addAnswer', userAnswersCopy);
  }

  const addTimeoutAnswer = () => {
    if (quizRef.current === null) return;
    if (quizRef.current.questions[currentQuestionRef.current] === undefined) return; // this can occur when 'finishing' the quiz 
    // i.e. when currentQuestion > question arr length (when finished). E.g. when queued execution occurs in the same ms as 
    // user clicking complete
    const quiz = quizRef.current;
    const userAnswersCop = userAnswersRef.current;

    const userAnswersCopy = JSON.parse(JSON.stringify(userAnswersCop));
    if (isMultipleChoiceQuestion(quiz.questions[currentQuestionRef.current])) {
      const timeoutAnsw: UserAnswerMultipleChoice = {
        questionId: quiz.questions[currentQuestionRef.current].id,
        answerId: [],
        timeout: true
      };
      userAnswersCopy.push(timeoutAnsw);
    } else if (isTextQuestion(quiz.questions[currentQuestionRef.current])) {
      const timeoutAnsw: UserAnswerText = {
        questionId: quiz.questions[currentQuestionRef.current].id,
        answer: '',
        timeout: true
      };
      userAnswersCopy.push(timeoutAnsw);
    } else if (isFlashCardQuestion(quiz.questions[currentQuestionRef.current])) {
      const timeoutAnsw: UserAnswerFlashCard = {
        questionId: quiz.questions[currentQuestionRef.current].id,
        answer: false,
        timeout: true,
      };
      userAnswersCopy.push(timeoutAnsw);
    }

    setUserAnswers(userAnswersCopy);
    console.log('addTimeoutAnswer', userAnswersCopy);
  }

  const addAllTimeoutAnswers = () => {
    if (quizRef.current === null) return;
    if (quizRef.current.questions[currentQuestionRef.current] === undefined) return; // this can occur when 'finishing' the quiz 
    // i.e. when currentQuestion > question arr length (when finished). E.g. when queued execution occurs in the same ms as 
    // user clicking complete
    const quiz = quizRef.current;
    const userAnswersCop = userAnswersRef.current;

    // Only add timeout answer values the current question and the questions ahead of it 
    const userAnswersCopy = JSON.parse(JSON.stringify(userAnswersCop));

    // Fill inn blanks for unanswered questions
    const unansweredQuestions = quizRef.current.questions.filter((el) => {
      return !userAnswersCopy.some((ans: UserAnswer) => ans.questionId === el.id);
    });

    for (let unansweredQuestion of unansweredQuestions) {
      if (isMultipleChoiceQuestion(unansweredQuestion)) {
        const timeoutAnsw: UserAnswerMultipleChoice = {
          questionId: unansweredQuestion.id,
          answerId: [],
          timeout: true
        };
        userAnswersCopy.push(timeoutAnsw);
      } else if (isTextQuestion(unansweredQuestion)) {
        const timeoutAnsw: UserAnswerText = {
          questionId: unansweredQuestion.id,
          answer: '',
          timeout: true
        };
        userAnswersCopy.push(timeoutAnsw);
      }
    }
    setUserAnswers(userAnswersCopy);
    console.log('addAllTimeoutAnswers', userAnswersCopy);

    // Enforce end of the quiz
    const endQuestionIndex = quiz.questions.length;
    setUserCompleteQuiz(true);
    resetGlobalTimer();
    submitUserResults(true, userAnswersCopy);
    setCurrentQuestion(endQuestionIndex);

  }

  const resetTimer = () => {
    clearInterval(timerRef.current);
    setTimerStarted(false);
    setTimerValue(0);
  }

  const resetGlobalTimer = () => {
    clearInterval(globalTimerRef.current);
    setGlobalTimerStarted(false);
    setGlobalTimerValue(0);
  }

  const checkStartTimer = (questionIndex: number, quiz: Quiz) => {
    resetTimer(); // always reset the timer
    setTimerTimedOut(false);
    const questionObj = quiz.questions[questionIndex];
    let questionTimer = quiz.defaultTimeLimit;
    questionTimer = questionObj.timeLimit !== null ? questionObj.timeLimit : questionTimer;
    if (questionTimer === null) return;
    if (questionTimer === 0) {
      console.log('questionTimer is 0 => skipping question timer');
      return;
    }

    // Only start timer if control flow is strict chronological, otherwise global/overall timer is the only reasonable choice.
    if (quizMeta !== null && quizMeta.control_flow !== QuizControlFlowLevel.StrictChronological) {
      console.log('control_flow is not StrictChronological => skipping question timer');
      return;
    }

    // timer was found, let's start it
    console.log('questionTimer is', questionTimer);
    setTimerValue(questionTimer);
    setTimerStarted(true);
    timerRef.current = setInterval(tickTimer, 1000);
  }

  const checkStartGlobalTimer = (quiz: Quiz) => {
    resetGlobalTimer(); // always reset the timer
    setGlobalTimerTimedOut(false);
    let globalTimer = quiz.overallTimeLimit;
    globalTimer = globalTimer !== null ? globalTimer : 0;

    if (globalTimer === null) return;
    if (globalTimer === 0) {
      console.log('globalTimer is 0 => skipping');
      return;
    }
    // timer was found, let's start it
    console.log('globalTimer is', globalTimer);
    setGlobalTimerValue(globalTimer);
    setGlobalTimerStarted(true);
    globalTimerRef.current = setInterval(tickGlobalTimer, 1000);
  }


  const tickTimer = () => {
    const newTimerValue = timerValueRef.current - 1;

    // Have we reached 0? 
    if (newTimerValue <= 0) {
      addTimeoutAnswer();
      setTimerTimedOut(true);
      resetTimer();
      return;
    }

    // Continue to tick
    setTimerValue(newTimerValue);
  }

  const tickGlobalTimer = () => {
    const newGlobalTimerValue = globalTimerValueRef.current - 1;

    // Have we reached 0?
    if (newGlobalTimerValue <= 0) {
      addAllTimeoutAnswers();
      setGlobalTimerTimedOut(true);
      resetGlobalTimer();
      return;
    }

    // Continue to tick
    setGlobalTimerValue(newGlobalTimerValue);
  }

  const goToNext = () => {
    if (quiz === null || quizMeta === null) {
      return;
    }

    // If free order, confirm with user that he/she wants to complete the quiz without answering all questions
    const allQuestionsAnswered = userAnswers.length >= quiz.questions.length;
    const isLastQuestion = currentQuestion === quiz.questions.length - 1;
    if (isLastQuestion && quizMeta.control_flow === QuizControlFlowLevel.RelaxedFreeOrder) {

      if (!allQuestionsAnswered) {
        const res = window.confirm("You have not answered all questions. Are you sure you want to submit?");
        if (!res) {
          return;
        }
      }


      // Fill inn blanks for unanswered questions
      const unansweredQuestions = quiz.questions.filter((el) => {
        return !userAnswers.some(ans => ans.questionId === el.id);
      });

      const userAnswersCopy = JSON.parse(JSON.stringify(userAnswers));
      for (let unansweredQuestion of unansweredQuestions) {
        if (isMultipleChoiceQuestion(unansweredQuestion)) {
          const timeoutAnsw: UserAnswerMultipleChoice = {
            questionId: unansweredQuestion.id,
            answerId: [],
            timeout: true
          };
          userAnswersCopy.push(timeoutAnsw);
        } else if (isTextQuestion(unansweredQuestion)) {
          const timeoutAnsw: UserAnswerText = {
            questionId: unansweredQuestion.id,
            answer: '',
            timeout: true
          };
          userAnswersCopy.push(timeoutAnsw);
        }
      }
      setUserAnswers(userAnswersCopy);
    }



    if (currentQuestion + 1 >= quiz.questions.length) {
      setUserCompleteQuiz(true);
      resetGlobalTimer();
      submitUserResults(false);
    } else {
      checkStartTimer(currentQuestion + 1, quiz);
    }
    const newQIndex = currentQuestion + 1;
    setCurrentQuestion(newQIndex);
  }

  const resetProgress = () => {
    resetTimer();
    resetGlobalTimer();
    setCurrentQuestion(0);
    setUserStartQuiz(false);
    setUserCompleteQuiz(false);
    setUserAnswers([]);
  };

  const startShuffled = () => {
    const quizObj = quiz as Quiz;

    const questionsCopy: Question[] = JSON.parse(JSON.stringify(quizObj.questions));
    questionsCopy.sort(() => 0.5 - Math.random())

    // shuffle questions 
    const newQuizObj = {
      ...quizObj!,
      questions: questionsCopy
    } as Quiz;

    setActiveSeed(uuidv4()); // reset seed 
    setQuiz(newQuizObj);
    setUserStartQuiz(true);
    checkStartTimer(0, newQuizObj);
    checkStartGlobalTimer(newQuizObj);
    setCurrentQuestion(0);
  }

  const startOrdered = () => {
    const quizObj = quiz as Quiz;

    const questionsCopy: Question[] = JSON.parse(JSON.stringify(quizObj.questions));
    questionsCopy.sort((a, b) => {
      return a.order - b.order;
    })

    // shuffle questions 
    const newQuizObj = {
      ...quizObj!,
      questions: questionsCopy
    } as Quiz;


    setActiveSeed(uuidv4()); // reset seed 
    setQuiz(newQuizObj);
    setUserStartQuiz(true);
    checkStartTimer(0, newQuizObj);
    checkStartGlobalTimer(newQuizObj);
    setCurrentQuestion(0);
  }

  const renderPagination = () => {
    if (quiz === null) return;
    const bound = 3;
    const paginationItems = [];
    for (let i = currentQuestion - bound; i < currentQuestion + bound; i++) {
      if (i < 0) continue;
      if (i > quiz.questions.length - 1) continue;
      paginationItems.push(<Pagination.Item
        key={`pagination-${i}`}
        active={i === currentQuestion}
        onClick={() => {
          setCurrentQuestion(i);
        }}
      >{i + 1}</Pagination.Item>);
    }
    return paginationItems;
  }

  const quizEmpty = quiz === null || quiz.questions.length === 0;

  if (quiz === null || quizEmpty || quizMeta === null) {
    return <> <Card>
      <Card.Title></Card.Title>
      <Card.Body>

        <div style={{ textAlign: 'center', width: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>

        {quiz === null && <>
          {/* <h3>Create or browse local </h3> 
        <Button onClick={createQuiz} style={{marginRight: 10}}>
          <i className="bi bi-plus"></i> Create Quiz</Button>
        <Button onClick={loadQuiz}><i className="bi bi-upload"></i> 
          {' '}Load Quiz</Button> <br />
        Load or create a quiz to get started.

        <hr /> 
         */}



        </>}
        {quiz !== null && quiz.questions.length === 0 && <>The quiz is empty. Load a new one or edit the one existing.</>}
      </Card.Body></Card></>;
  }

  if (!userStartQuiz) {
    return <> <Card>
      <Card.Title>Quiz: {quiz.title}</Card.Title>
      <Card.Body>
        Statrt quiz ordinary order or shuffle questions? <br />
        <Button onClick={startShuffled} style={{ marginRight: 10 }}><i className="bi bi-shuffle"></i> Start (shuffle)</Button>
        <Button onClick={startOrdered}><i className="bi bi-play"></i> Start (ordered)</Button>
      </Card.Body></Card></>;
  }

  const quizLength = quiz.questions.length;
  // const quizProgress = ((currentQuestion) / quizLength) * 100;
  const quizProgress = (userAnswers.length / quizLength) * 100;

  const isFinished = userCompleteQuiz;
  const currentQuestionObj = quiz.questions[currentQuestion];

  // const currentQuestionAnswered = userAnswers.length > currentQuestion;
  const currentQuestionAnswered = currentQuestionObj === undefined ? true : userAnswers.some(a => a.questionId === currentQuestionObj.id);

  return <>
    <Card>
      <Card.Body>
        <Card.Title>{quiz.title}</Card.Title>
        <Card.Text>
          {quiz.description}
        </Card.Text>

        <hr />

        <Row>
          <Col><strong>Progress: {userAnswers.length}/{quizLength}</strong></Col>
          <Col style={{ textAlign: 'right' }}>
            {globalTimerStarted && <>{humanizeDuration(globalTimerValue * 1000)}</>}
          </Col>
        </Row>
        <ProgressBar animated={!isFinished} variant={isFinished ? 'success' : 'default'} now={quizProgress} label={`${quizProgress.toFixed(0)}%`} />


        {/* <Row>
          <Col><strong>Progress: {currentQuestion}/{quizLength}</strong></Col>
          <Col style={{ textAlign: 'right' }}>
            {globalTimerStarted && <>{humanizeDuration(globalTimerValue * 1000)}</>}
          </Col>
        </Row> */}
        {/* <ProgressBar animated={!isFinished} variant={isFinished ? 'success' : 'default'} now={quizProgress} label={`${quizProgress.toFixed(0)}%`} /> */}
      </Card.Body>
    </Card>

    <br />

    {/* Show pagination for free order flow */}
    {quizMeta.control_flow === QuizControlFlowLevel.RelaxedFreeOrder && !isFinished && <>
      <Pagination>
        <Pagination.First disabled={currentQuestion === 0} onClick={() => {
          setCurrentQuestion(0);
        }} />
        <Pagination.Prev disabled={currentQuestion === 0} onClick={() => {
          setCurrentQuestion(currentQuestion - 1);
        }} />

        {renderPagination()}

        <Pagination.Next disabled={currentQuestion === quiz.questions.length - 1 || quiz.questions.length === 0} onClick={() => {
          setCurrentQuestion(currentQuestion + 1);
        }} />
        <Pagination.Last disabled={currentQuestion === quiz.questions.length - 1 || quiz.questions.length === 0} onClick={() => {
          setCurrentQuestion(quiz.questions.length - 1);
        }} />
      </Pagination>
    </>}

    {/* <Card style={{ width: '36rem' }}> */}
    <Card>

      {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
      {isFinished && <>
        <QuizResults quiz={quiz} userAnswers={userAnswers} resetProgress={resetProgress} />
      </>}
      {!isFinished && userStartQuiz && <>
        <Row>
          <Col>
            <Card.Body>
              <Card.Title>
                <Row>
                  <Col>Question {currentQuestion + 1} </Col>
                  <Col style={{ textAlign: 'right' }}>
                    {quizMeta.control_flow === QuizControlFlowLevel.StrictChronological && <>
                      <span style={{ fontSize: '0.8em' }}>{timerStarted && <>{humanizeDuration(timerValue * 1000)}</>}</span>
                    </>}
                  </Col>
                </Row>
              </Card.Title>
              {isMultipleChoiceQuestion(currentQuestionObj) &&
                <MultipleChoiceQuestionForm
                  question={currentQuestionObj}
                  questionIndex={currentQuestion}
                  doSubmitAnswer={addAnswer}
                  goToNext={goToNext}
                  isAnswered={currentQuestionAnswered}
                  hasTimeout={timerTimedOut}
                  quizMeta={quizMeta}
                />}
              {isTextQuestion(currentQuestionObj) && <>
                <TextQuestionForm
                  question={currentQuestionObj}
                  questionIndex={currentQuestion}
                  doSubmitAnswer={addAnswer}
                  goToNext={goToNext}
                  isAnswered={currentQuestionAnswered}
                  hasTimeout={timerTimedOut}
                  quizMeta={quizMeta}
                />
              </>}
              {isFlashCardQuestion(currentQuestionObj) && <>
                <FlashCardQuestionForm
                  question={currentQuestionObj}
                  questionIndex={currentQuestion}
                  doSubmitAnswer={addAnswer}
                  goToNext={goToNext}
                  isAnswered={currentQuestionAnswered}
                  hasTimeout={timerTimedOut}
                  quizMeta={quizMeta}
                />
              </>}
              <Card.Footer className="text-muted">
                <span style={{ marginLeft: 10, marginRight: 10 }}>Question by</span>
                <Avatar round={true} name={currentQuestionObj.createdBy} color={getUserColor(currentQuestionObj.createdBy)} size="30"
                  textSizeRatio={2}
                />
                <span style={{ marginLeft: 10, marginRight: 10 }}>{currentQuestionObj.createdBy}</span>
              </Card.Footer>
            </Card.Body>
          </Col>
        </Row>
      </>
      }


    </Card>
  </>;
}