import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Form, FormControl, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { userAccessTokenState, userProfileState } from "../state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "../state/share";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";
import { validateCountry, validateUsername } from "../validators/user";
import { getAPIPath } from "../core";
import { countryList } from "../data";


export const EnterInviteCode = () => {

  const navigate = useNavigate();

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, } = useAuth0();
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);

  const [isLoading, setIsLoading] = useState(false);

  const [inviteCode, setInviteCode] = useState("");

  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  const activateInvitation = async () => {

    setIsLoading(true);
    const putRes = await fetch(getAPIPath() + `/api/user/profile/invite`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        sharecode: inviteCode,
      })
    });

    if (putRes.status === 400) {
      toast.error("Invalid invite code");
      setIsLoading(false);
      return;
    }

    if (putRes.status !== 200) {
      setIsLoading(false);
      toast.error("Error accepting invite");
      return;
    }

    // Update ok, fetch new user profile
    const fetchRes = await fetch(getAPIPath() + `/api/user/profile`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
      },
      method: 'GET'
    });

    if (fetchRes.status !== 200) {
      setIsLoading(false);
      toast.error("Error fetching profile");
      return;
    }

    const data = await fetchRes.json();
    setUserProfile(data);
    setIsLoading(false);
    toast.success("Invite code accepted.");
    navigate(`/`);
  }

  return <>
    <Modal
      show={true}
      onHide={() => { }}
      backdrop="static"
      keyboard={false}
      size={'lg'}
    >
      <Modal.Header>
        <Modal.Title>Enter invite code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={9}>
            <strong>Invite code</strong>
            <InputGroup>

              <FormControl
                type="text"
                aria-label="default"
                aria-describedby="inputGroup-quiz-time"
                value={inviteCode}
                onChange={(e) => {
                  setInviteCode(e.target.value);
                  // setCodeStr(e.target.value);
                }}
              // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
              />
            </InputGroup>
          </Col>
          <Col md={3} className='d-none d-md-block'>
            {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
            <img src="/images/lock.svg" style={{ maxWidth: 80, minWidth: 60 }} />
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="lg" style={{ width: '100%' }} disabled={
          isLoading
        } onClick={() => {
          activateInvitation();
        }}>
          {isLoading && <><Spinner animation="border" variant="primary" as="span" /></>}
          {!isLoading && <>Validate</>}
        </Button>

      </Modal.Footer>
    </Modal>
  </>;
}