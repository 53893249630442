import stringSimilarity from 'string-similarity';

export const validateTextAnswer = (trueValue: string, inputValue: string) => {
  const trueValueCleaned = trueValue.trim().toLowerCase();
  const inputValueCleaned = inputValue.trim().toLowerCase();

  // If numeric, validate to 2 decimals
  if (!isNaN(Number(trueValueCleaned))) {
    return Number(inputValueCleaned).toFixed(2) === Number(trueValueCleaned).toFixed(2);
  }

  // If not numeric, check if similar
  const threshold = 0.85;  

  const similarity = stringSimilarity.compareTwoStrings(trueValueCleaned, inputValueCleaned);
  console.log('similarity', similarity);
  return similarity >= threshold;
}