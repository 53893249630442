import { atom, RecoilState } from "recoil";
import { EditMode, Quiz, UserAnswer, ServerUserProfile, QuizMeta } from "../quiz-types";

export const activeQuiz: RecoilState<Quiz|null> = atom({
  key: 'activeQuiz',
  default: null as Quiz|null
})

export const activeQuizMeta: RecoilState<QuizMeta|null> = atom({
  key: 'activeQuizMeta',
  default: null as QuizMeta|null
})

export const editQuizModeState: RecoilState<EditMode|null> = atom({
  key: 'editQuizModeState',
  default: null as EditMode|null
});

export const currentQuestionState = atom({
  key: 'currentQuestionState',
  default: 0
})

export const currentUserAnswersState: RecoilState<UserAnswer[]> = atom({
  key: 'currentUserAnswers',
  default: [] as UserAnswer[]
})

export const userStartQuizState = atom({
  key: 'userStartQuizState',
  default: false
})
export const userCompleteQuizState = atom({
  key: 'userCompleteQuizState',
  default: false
})

export const userAccessTokenState = atom({
  key: 'userAccessTokenState',
  default: ''
});

export const userProfileState: RecoilState<ServerUserProfile|null> = atom({
  key: 'userProfileState',
  default: null as ServerUserProfile|null
});