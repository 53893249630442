import { Button, Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import React from "react";
import { MultipleChoiceAnswer, MultipleChoiceQuestion, Question, Quiz, QuizControlFlowLevel, QuizMeta } from "../quiz-types";
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import hash from 'object-hash';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import { EditMultipleChoiceQuestion } from "./EditMultipleChoiceQuestion";
import { EditTextQuestion } from "./EditTextQuestion";

import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'


interface Props {
  quiz: Quiz
  quizMeta: QuizMeta,
  question: Question
  questionIndex: number
  moveQuestionUp(question: Question): void
  moveQuestionDown(question: Question): void
  removeQuestion(question: Question): void
  updateQuestion(question: Question): void
}

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = hash(prevProps.question) === hash(nextProps.question)
    && prevProps.questionIndex === nextProps.questionIndex
    && prevProps.quiz.questions.length === nextProps.quiz.questions.length;
  return equal;
}

const EditQuizQuestionComponent = (props: Props) => {

  const { quiz, quizMeta, question, questionIndex, moveQuestionUp, moveQuestionDown, removeQuestion, updateQuestion } = props;
  const { control_flow } = quizMeta;

  const questionObj = question as MultipleChoiceQuestion;



  const updateQuizDescription = (questionBody: string, katexQuestionBody: string) => {
    updateQuestion({ ...question, questionBody, katexQuestionBody });
  }

  const updateTimeLimit = (timeLimit: string) => {
    const timeLimitVal = (timeLimit.trim() === '' || timeLimit === null) ? null : Number(timeLimit);
    updateQuestion({ ...question, timeLimit: timeLimitVal });
  }

  const updateQuizSolutionExplanation = (solutionExplanation: string, solutionExplanationKatex: string) => {
    updateQuestion({ ...question, solutionExplanation, solutionExplanationKatex });
  }

  const isFlashcard = question.type === "FlashCardQuestion";

  return <Card>
    <Card.Body>
      <Card.Title>
        Question - {questionIndex + 1}
        <p style={{ fontWeight: 'normal' }}>
          {question.type === "MultipleChoiceQuestion" && 'Multiple Choice'}
          {question.type === "TextQuestion" && 'Text Input'}
          {question.type === "FlashCardQuestion" && 'Flashcard'}
          </p>
      </Card.Title>
      <Row>
        <Col>
          {questionIndex !== 0 && <Button variant="dark"
            onClick={() => { moveQuestionDown(question); }}
          ><i className="bi bi-arrow-up"></i></Button>}
          {questionIndex !== quiz.questions.length - 1 && <Button variant="dark"
            onClick={() => { moveQuestionUp(question); }}
          ><i className="bi bi-arrow-down"></i></Button>}

        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Button
            variant="danger"
            onClick={() => { removeQuestion(question); }}
          >
            <i className="bi bi-trash"></i>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>Input</Col>
        <Col>Preview</Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup>
              <InputGroup.Text>Question body {isFlashcard && <>(Flashcard front)</>}</InputGroup.Text>
            </InputGroup>
            <FormControl
              as="textarea"
              aria-label="With textarea"
              value={questionObj.questionBody}
              onChange={(e) => { updateQuizDescription(e.target.value, questionObj.katexQuestionBody); }}
            />
          </InputGroup>
        </Col>
        <Col>
          <ReactMarkdown className='app-markdown'
            // @ts-ignore
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
          >{questionObj.questionBody}</ReactMarkdown>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup>
              <InputGroup.Text>Question body (Katex) {isFlashcard && <>(Flashcard front)</>}</InputGroup.Text>
            </InputGroup>
            <FormControl
              as="textarea"
              aria-label="With textarea"
              value={questionObj.katexQuestionBody}
              onChange={(e) => { updateQuizDescription(questionObj.questionBody, e.target.value); }}
            // onChange={(e) => { setQuiz({ ...quiz, description: e.target.value }); }}
            />
          </InputGroup>
        </Col>
        <Col>
          {questionObj.katexQuestionBody !== '' && <TeX math={questionObj.katexQuestionBody} block />}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <InputGroup>
            <InputGroup>
              <InputGroup.Text>Solution Explanation {isFlashcard && <>(Flashcard backside)</>}</InputGroup.Text>
            </InputGroup>
            <FormControl
              as="textarea"
              aria-label="With textarea"
              value={questionObj.solutionExplanation}
              onChange={(e) => { updateQuizSolutionExplanation(e.target.value, questionObj.solutionExplanationKatex); }}
            />
          </InputGroup>
        </Col>
        <Col>
          <ReactMarkdown className='app-markdown'
            // @ts-ignore
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeKatex]}
          >{questionObj.solutionExplanation}</ReactMarkdown>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup>
              <InputGroup.Text>Solution Explanation (Katex) {isFlashcard && <>(Flashcard backside)</>}</InputGroup.Text>
            </InputGroup>
            <FormControl
              as="textarea"
              aria-label="With textarea"
              value={questionObj.solutionExplanationKatex}
              onChange={(e) => { updateQuizSolutionExplanation(questionObj.solutionExplanation, e.target.value); }}
            // onChange={(e) => { setQuiz({ ...quiz, description: e.target.value }); }}
            />
          </InputGroup>
        </Col>
        <Col>
          {questionObj.solutionExplanationKatex !== '' && <TeX math={questionObj.solutionExplanationKatex} block />}
        </Col>
      </Row>

      <hr />
      <h4>Time limit</h4>
      {control_flow === QuizControlFlowLevel.RelaxedFreeOrder && <span style={{fontStyle: 'italic'}}>
        Individual question time limit disabled when using free order quiz control flow.
      </span>}
      {control_flow === QuizControlFlowLevel.StrictChronological && <Row>
        <Col>
          <InputGroup>
            <InputGroup>
              <InputGroup.Text>Time limit in seconds (blank for default)</InputGroup.Text>
            </InputGroup>
            <FormControl
              aria-label="With textarea"
              value={questionObj.timeLimit === null ? '' : questionObj.timeLimit.toString()}
              onChange={(e) => { updateTimeLimit(e.target.value); }}
            // onChange={(e) => { setQuiz({ ...quiz, description: e.target.value }); }}
            />
          </InputGroup>
        </Col>
        <Col>
        </Col>
      </Row>}

      <hr />

      {question.type === "MultipleChoiceQuestion" && <EditMultipleChoiceQuestion
        question={question}
        updateQuestion={updateQuestion}
        questionIndex={questionIndex}
      />}
      {question.type === "TextQuestion" && <EditTextQuestion
        question={question}
        updateQuestion={updateQuestion}
        questionIndex={questionIndex}
      />}

    </Card.Body>
  </Card>
}


export const EditQuizQuestion = React.memo(EditQuizQuestionComponent, areEqual);