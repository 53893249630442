import { useEffect, useState } from "react";
import { Button, Card, CloseButton, Col, Form, FormControl, InputGroup, ListGroup, ListGroupItem, Modal, Row, Spinner } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { userAccessTokenState, userProfileState } from "../state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "../state/share";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";
import { validateCountry, validateUsername } from "../validators/user";
import { getAPIPath } from "../core";
import { countryList } from "../data";
import { SubScriptionTypes } from "../types";


interface Props {
  closeable?: boolean
  onCloseUpgradeModal?: () => void
}

export const SetupSubscription = (props: Props) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  const beginSubscription = async (subscriptionId: SubScriptionTypes) => {
    setIsLoading(true);

    const beginSubscrptionProcess = await fetch(getAPIPath() + `/api/user/subscription/${subscriptionId}/begin`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      },
      method: 'GET'
    });


    if (beginSubscrptionProcess.status !== 200) {
      toast.error("Failed to begin subscription");
      return;
    }

    const sessionData = await beginSubscrptionProcess.json();

    if (subscriptionId === SubScriptionTypes.Free) { 

      // Free subscription selected, re-fetch user profile and update state
      // Update ok, fetch new user profile
      const fetchRes = await fetch(getAPIPath() + `/api/user/profile`, {
        headers: {
          'Authorization': `Bearer ${userAccessToken}`,
        },
        method: 'GET'
      });

      if (fetchRes.status !== 200) {
        setIsLoading(false);
        toast.error("Error fetching profile");
        return;
      }

      // Update user profile
      const data = await fetchRes.json();
      setUserProfile(data);
      setIsLoading(false);
      toast.success("Free plan subscription started");
      navigate("/");
      return;

    }

    // Subscription is paid, begin redirecting to payment page

    setIsLoading(false);
    window.location.href = sessionData.url;


  }

  return <>
    <Modal
      show={true}
      onHide={() => {

        if (props.closeable) {
          if (props.onCloseUpgradeModal !== undefined) props.onCloseUpgradeModal();
          navigate(`/profile/${userProfile?.user.sharecode}`);
        }
      }}
      backdrop="static"
      keyboard={false}
      size={'lg'}
      
    >
      <Modal.Header closeButton={props.closeable === true}>
        <Modal.Title>Select plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Row>
          <Col md={6} sm={12} className="d-flex align-items-stretch">
            <Card style={{ width: '100%' }}>
              {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
              <Card.Body>
                <Card.Title>
                  <h2 style={{color: '#1268CE', textAlign: 'center'}}>Eduerity Free</h2>
                </Card.Title>
                <Card.Text>
                  <div style={{ textAlign: 'center' }}>
                    <h3>
                      <small style={{ fontSize: '1rem', transform: 'translateY(-1.3rem)', display: 'inline-block' }}>$</small>
                      <span style={{ fontSize: '3rem' }}>0</span>
                      <small style={{ fontSize: '1rem', transform: 'translateY(-1.3rem)', display: 'inline-block' }}>per month</small>
                    </h3>
                  </div>
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroupItem><i style={{color: '#1268CE'}} className="bi bi-check"></i> 3 private quizes</ListGroupItem>
                <ListGroupItem><i style={{color: '#1268CE'}} className="bi bi-check"></i>Unlimited public quizes</ListGroupItem>
                <ListGroupItem></ListGroupItem>
              </ListGroup>
              <Card.Body>
                <Button variant="primary" style={{ width: '100%' }} disabled={
                  isLoading
                } onClick={() => {
                  beginSubscription(SubScriptionTypes.Free);
                }}>
                  {isLoading && <><Spinner animation="border" variant="primary" as="span" /></>}
                  {!isLoading && <>Choose Plan</>}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} sm={12} className="d-flex align-items-stretch">
            <Card style={{ width: '100%' }}>
              {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
              <Card.Body>
                <Card.Title>
                  <h2 style={{ color: '#0aa3a3', textAlign: 'center' }}>
                    Eduerity Plus
                  </h2>
                </Card.Title>
                <Card.Text style={{ textAlign: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <h3>
                    <small style={{ fontSize: '1rem', transform: 'translateY(-1.3rem)', display: 'inline-block' }}>$</small>
                    <span style={{ fontSize: '3rem' }}>3</span>
                    <small style={{ fontSize: '1rem', transform: 'translateY(-1.3rem)', display: 'inline-block' }}>per month</small>
                    </h3>
                  </div>
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroupItem><i style={{ color: '#0aa3a3' }} className="bi bi-check"></i>Unlimited private quizes</ListGroupItem>
                <ListGroupItem><i style={{ color: '#0aa3a3' }} className="bi bi-check"></i>Unlimited public quizes</ListGroupItem>
                <ListGroupItem><i style={{ color: '#0aa3a3' }} className="bi bi-check"></i>My Learning - track performance</ListGroupItem>
              </ListGroup>
              <Card.Body>
                <Button variant="primary" style={{ width: '100%' }} disabled={
                  isLoading
                } onClick={() => {
                  beginSubscription(SubScriptionTypes.Plus);
                }}>
                  {isLoading && <><Spinner animation="border" variant="primary" as="span" /></>}
                  {!isLoading && <>Choose Plan</>}
                </Button>
                {/* <Card.Link href="#">Card Link</Card.Link>
                <Card.Link href="#">Another Link</Card.Link> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" size="lg" style={{ width: '100%' }} disabled={
          !isLoading
        } onClick={() => {
          // updateProfile();
        }}>
          {isLoading && <><Spinner animation="border" variant="primary" as="span" /></>}
          {!isLoading && <>Done</>}
        </Button> */}

      </Modal.Footer>
    </Modal>
  </>;

}