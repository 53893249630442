import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import Avatar from 'react-avatar';
import { Button, Container, Form, FormControl, Nav, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { LoginModal } from './LoginModal';
import { userAccessTokenState, userProfileState } from './state/mainstate';
import { getUserColor } from './user';

// Mother component with theme, base for all children components
export const MainTheme = ({ children }: any) => {

  const navigate = useNavigate();

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();


  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);



  useEffect(() => {
    const domain = "eduerity.eu.auth0.com";
    const getToken = async () => {
      if (!isAuthenticated) { return; }
      const accessToken = await getAccessTokenSilently();
      setUserAccessToken(accessToken);
      console.log('accessToken', accessToken);
    }


    getToken();

  }, [getAccessTokenSilently, user?.sub]);

  const loggedInAndSetup = isAuthenticated && user !== undefined && userProfile !== null && userProfile.user.profile_setup;

  return <>
    <div>
      {/* {isAuthenticated && !user!!.email_verified && <NotVerifiedModal />} */}

      <Navbar bg="primary" variant="dark" className='eduerity-navbar' collapseOnSelect expand="lg">
        <Navbar.Brand><span style={{ cursor: 'pointer', paddingLeft: 15 }} onClick={() => {
          navigate('/');
        }}>Eduerity</span></Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* {loggedInAndSetup && <>
              <Nav.Link href="#action1">Home</Nav.Link>
               <Nav.Link href="#action2">Link</Nav.Link>
            </>} */}

          <Nav className="me-auto">

          {/* Nav Options Only for authenticated users */}
          {loggedInAndSetup && <>
            <Nav.Link onClick={() => { navigate('/'); }}>Browse</Nav.Link>
            <Nav.Link onClick={() => { navigate('/learning'); }}>My Learning</Nav.Link>
            <Nav.Link onClick={() => { navigate('/collection'); }}>Collections</Nav.Link>
          </>}

          {/* Nav Options for non-authenticated */}
          {!loggedInAndSetup && <>
            <Nav.Link onClick={() => { navigate('/'); }}>Home</Nav.Link>
          </>}

          </Nav>
          <Nav className="ms-auto">


            <Form>

              {isAuthenticated && user !== undefined && userProfile !== null && <>

                {/* USER is NOT SETUP  */}
                {!userProfile.user.profile_setup && <><Avatar round={true} name={user.name as string} color={getUserColor(user.name as string)} size="30"
                  textSizeRatio={2}
                />
                  <span style={{ marginLeft: 10, marginRight: 10, color: 'white', fontWeight: 'bold' }}>
                    {user.name}
                  </span></>}

                {/* USER is COMPLETELY SETUP  */}
                {userProfile.user.profile_setup && <><Avatar round={true} name={userProfile.user.nickname as string} color={getUserColor(userProfile.user.nickname as string)} size="30"
                  textSizeRatio={2}
                />
                  <span style={{ marginLeft: 10, marginRight: 10, color: 'white', fontWeight: 'bold' }}>
                    <Link to={`/profile/${userProfile.user.sharecode}`} style={{ color: 'white' }}>{userProfile.user.nickname}</Link>
                  </span></>}

              </>


              }
              {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}

              {!isAuthenticated && <Button variant="outline-light" onClick={() => () => {
                loginWithRedirect({ authorizationParams: {
                  screen_hint: 'login',
                  redirect_uri: window.location.origin + '/',
                }});
              }}>Login / Sign Up</Button>}
              {isAuthenticated && <Button variant="outline-light" onClick={() => logout({
            logoutParams: {
              returnTo: window.location.origin + '/',
            }
          })}>Logout</Button>}


              {/* {login && <Button variant="outline-light" style={{ marginRight: 5 }} onClick={changeName}>Change name</Button>} */}

              {/* {isAuthenticated && <>
            <Button variant="outline-light" style={{ marginRight: 5 }}
              onClick={loadQuiz}
            ><i className="bi bi-upload"></i> Load quiz</Button>
            <Button variant="outline-light"
              onClick={saveQuiz}
            ><i className="bi bi-save"></i> Save quiz</Button>
          </>} */}


            </Form>
            <Form className="d-flex">
              {/* <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button> */}
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {!isAuthenticated && <LoginModal />}
      <>
        {userAccessToken !== '' && children}
      </>
    </div>
  </>;
}