import { useEffect, useState } from "react";
import { Button, Card, CloseButton, Col, FormControl, InputGroup, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { userAccessTokenState } from "../state/mainstate";
import { ServerAuthor, ServerQuizFull } from "../quiz-types";
import { toast } from "react-toastify";
import { getAPIPath } from "../core";
import { getUserColor } from "../user";

export const JoinByCode = () => {

  const navigate = useNavigate();

  const [codeStr, setCodeStr] = useState(useParams().code);

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, } = useAuth0();

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);


  const [usernameInput, setUsernameInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lookedUp, setLookup] = useState(false);
  const [lookupQuiz, setLookupQuiz] = useState<ServerQuizFull | null>(null);
  const [authors, setAuthors] = useState<ServerAuthor[]>([]);

  const lookup = async (lookupSharecode: string) => {
    setIsLoading(true);
    const res = await fetch(getAPIPath() + `/api/quiz/quiz/${lookupSharecode}`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
      }
    });
    if (res.status !== 200) {
      setLookupQuiz(null);
      setIsLoading(false);
      toast.error("Quiz not found.");
      return;
    }
    const data = await res.json();

    // The quiz exists, so set it
    setLookupQuiz(data.quiz);
    setAuthors(data.authors);
    setLookup(true);
    setIsLoading(false);
  }

  const subscribe = async (lookupSharecode: string) => {
    setIsLoading(true);
    const res = await fetch(getAPIPath() + `/api/quiz/quiz/me/subscriptions/${lookupSharecode}`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
      },
      method: 'POST'
    });

    if (res.status !== 200) {
      setIsLoading(false);
      toast.error("Error subscribing to quiz");
      return;
    }

    const data = await res.json();
    setIsLoading(false);
    toast.success("Subscribed to quiz");
    navigate(`/quiz/${lookupSharecode}`);

  };

  useEffect(() => {
    if (codeStr !== '' && codeStr !== undefined) {
      lookup(codeStr as string);
    }
  }, []);

  return <>
    <Modal
      show={true}
      onHide={() => { }}
      backdrop="static"
      keyboard={false}
      size={'lg'}
    >
      <Modal.Header>
        <Modal.Title>Join by code</Modal.Title>
        <CloseButton onClick={() => {
          navigate("/");
        }} />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={8}>
            {!lookedUp && <div>
              <strong>Enter code</strong>
              <InputGroup>
                <InputGroup.Text
                  id="inputGroup-quiz-time"
                  style={{}}
                >Code<br /></InputGroup.Text>
                <FormControl
                  type="text"
                  aria-label="default"
                  aria-describedby="inputGroup-quiz-time"
                  value={codeStr}
                  onChange={(e) => {
                    setCodeStr(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      lookup(codeStr as string);
                    }
                  }}
                // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
                />
              </InputGroup>
            </div>}
            {lookedUp && lookupQuiz !== null && <div>
              <Card>
                <ListGroup>
                  <ListGroup.Item>
                    <strong>Quiz: {lookupQuiz.name}</strong>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Creator: </strong>
                    {lookupQuiz.creator}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Questions:</strong>
                    {lookupQuiz.content.questions.length}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Default time limit:</strong>
                    {lookupQuiz.content.defaultTimeLimit}s
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Authors:</strong>
                    <ListGroup>
                      {authors.sort((a, b) => {
                        return a.user_id === lookupQuiz.creator ? -1 : 1;
                      }).map((author, index) => {
                        return <ListGroup.Item action key={index}>
                          <Avatar name={author.nickname} size="30" round={true} color={getUserColor(author.nickname)} />
                          <span>{author.nickname}
                            {author.user_id === lookupQuiz.creator && <span> (<strong>creator</strong>)</span>}
                          </span>
                        </ListGroup.Item>
                      })}
                    </ListGroup>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </div>}
          </Col>
          <Col md={4}>
            {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
            <img src="/images/key.svg" style={{ maxWidth: 80 }} />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>

        {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
          <Spinner animation="border" variant="primary" />
        </div>}

        {!isLoading && !lookedUp && <><Button variant="primary" size="lg" style={{ width: '100%' }} onClick={() => {
          lookup(codeStr as string);
        }}>Lookup</Button></>}
        {!isLoading && lookedUp && <><Button variant="primary" size="lg" style={{ width: '100%' }} onClick={() => {
          subscribe(codeStr as string);
        }}>Subscribe</Button>
          <Button variant="secondary" size="lg" style={{ width: '100%' }} onClick={() => {
            setCodeStr('');
            setLookupQuiz(null);
            setLookup(false);
          }}>Cancel</Button>
        </>}


      </Modal.Footer>
    </Modal>
  </>;
}