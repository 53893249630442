import { useEffect, useState } from "react";
import { Button, CloseButton, Col, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { userAccessTokenState } from "../state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "../state/share";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";


export const ShareQuiz = () => {

  const navigate = useNavigate();

  const [shareQuiz, setShareQuiz] = useRecoilState(activeShareQuizState);
  const [shareCodeOpen, setShareCodeOpen] = useRecoilState(activeShareWindowState);

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup,  } = useAuth0();
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);

  const [usernameInput, setUsernameInput] = useState('');

  const getShareableLink = () => {
    return `${window.location.origin}/join/${(shareQuiz?.sharecode)}`;
  }

  return <> 
  <Modal
    show={shareCodeOpen}
    onHide={() => {}}
    backdrop="static"
    keyboard={false}
    size={'lg'}
  >
    <Modal.Header>
      <Modal.Title>Share "{shareQuiz?.name}"</Modal.Title>
      <CloseButton onClick={() => {
        setShareCodeOpen(false);
        setShareQuiz(null);
      }} />
    </Modal.Header>
    <Modal.Body>
    <Row>
    <Col md={9}>
    <strong>Sharable link</strong>
      <InputGroup>
        
        <FormControl
            type="text"
            aria-label="default"
            aria-describedby="inputGroup-quiz-time"
            value={getShareableLink()}
            onChange={(e) => {
              // setCodeStr(e.target.value);
            }}
            readOnly
            // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
          />
          <Button 
            variant="outline-secondary" 
            id="button-addon2"
            onClick={() => {
              copy(getShareableLink());
              toast.success("Link copied to clipboard");
            }}
          >
            Copy the link
          </Button>
         
      </InputGroup>
      <br />
      <strong>Share Code</strong>
      <InputGroup>
       
        <FormControl
            type="text"
            aria-label="default"
            aria-describedby="inputGroup-quiz-time"
            value={shareQuiz?.sharecode}
            onChange={(e) => {
              // setCodeStr(e.target.value);
            }}
            readOnly
            // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
          />
          <Button 
            variant="outline-secondary" 
            id="button-addon2"
            onClick={() => {
              copy(shareQuiz?.sharecode as string);
              toast.success("Code copied to clipboard");
            }}
          >
            Copy the code
          </Button>
      </InputGroup>
    </Col>
    <Col md={3}>
      {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
      <img src="/images/key.svg" style={{maxWidth: 80}} />
    </Col>
    </Row>
    
    <Row>
    <Col sm={12}>
    </Col>
    </Row>

    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" size="lg" style={{width: '100%'}} onClick={() => {
      setShareCodeOpen(false);
      setShareQuiz(null);
    }}>Done</Button>
    
    </Modal.Footer>
  </Modal>
  </>;
}