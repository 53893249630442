import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { userAccessTokenState, userProfileState } from "../state/mainstate";

export const RedirectSubscription = () => {



  const [searchParams, setSearchParams] = useSearchParams();

  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  // // Get action from url params
  const { action } = useParams(); // action can be success or cancel

  // // Read session_id from query params
  const sessionId = searchParams.get("session_id");

  
  // const sendToHomepage = () => {


    
  //   toast.success("Subscription successful");
  // }

  // useEffect(() => {
  //   sendToHomepage();
  // }, []);

    //   {/* success_url: `${process.env.APP_URL}/profile/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
    // cancel_url: `${process.env.APP_URL}/profile/subscription/cancel`, */}
  
  if (action === 'success') {
    return <div><h1>Success</h1></div>
  } else if (action === 'cancel') {
    return <div><h1>Cancelled</h1></div>
  } else { 
    return <div>Unknown status</div>
  }
  // return <div>Redirecting...</div>;
  // return <div>hello. Action: {action}, sessionId: {sessionId}</div>;
}