import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { getAPIPath } from "../core";
import { ServerInviteCode, ServerUserQuizResults, ServerUserProfile } from "../quiz-types";
import { userAccessTokenState, userProfileState } from "../state/mainstate";
import copy from 'clipboard-copy';
import { SetupSubscription } from "../Modals/SetupSubscription";
import { ChangeUserInfo } from "../Modals/ChangeUserInfo";

interface Props {
  upgrade?: boolean
}

export const UserProfile = (props: Props) => {

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [upgradePlanModalOpen, setUpgradePlanModalOpen] = useState(props.upgrade === true);
  const [changeInfoModalOpen, setChangeInfoModalOpen] = useState(false);

  const [profileResult, setProfileResult] = useState<ServerUserProfile | null>(null);
  const [inviteCodes, setInviteCodes] = useState<ServerInviteCode[]>([]);

  useEffect(() => {
    loadMyLearning();


    return (() => {
      // Cleanup
      setUpgradePlanModalOpen(false);
    });

  }, []);

  const loadMyLearning = async () => {
    setIsLoading(true);


    const userInvitesResp = fetch(getAPIPath() + '/api/user/invites', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    const userProfileResp = fetch(getAPIPath() + '/api/user/profile', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    const [userInvitesDone, userProfileDone] = await Promise.all([userInvitesResp, userProfileResp]);

    if (userInvitesDone.status !== 200 || userProfileDone.status !== 200) {
      toast.error("Failed to load user information");
      return;
    }

    const userInvitesData = await userInvitesDone.json();
    const userProfileData = await userProfileDone.json();

    // setUserProfile(userProfileData[0]);
    setInviteCodes(userInvitesData);
    setUserProfile(userProfileData);
    setIsLoading(false);
    setIsLoaded(true);

    // setMyQuizResults(resultsList.quizResults);

    // setIsLoading(false);
    // setIsLoaded(true);

  }

  const beginSubscription = async (subscriptionId: number) => {

    const beginSubscrptionProcess = await fetch(getAPIPath() + `/api/user/subscription/${subscriptionId}/begin`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      },
      method: 'GET'
    });


    if (beginSubscrptionProcess.status !== 200) {
      toast.error("Failed to begin subscription");
      return;
    }

    const sessionData = await beginSubscrptionProcess.json();

    window.location.href = sessionData.url;


  }


  const onCloseUpgradeModal = () => {
    setUpgradePlanModalOpen(false);
  }

  const onCloseChangeUserInfoModal = () => {
    setChangeInfoModalOpen(false);
    // Reload user profile
    loadMyLearning();
  }

  return <>
    {upgradePlanModalOpen && <SetupSubscription closeable={true} onCloseUpgradeModal={onCloseUpgradeModal} />}
    {changeInfoModalOpen && <ChangeUserInfo closeable={true} onCloseModal={onCloseChangeUserInfoModal} />}
    <Row>
      <Col>


        <Card>
          <Card.Title>
            <h3>My Profile</h3>
            <hr />
          </Card.Title>
          <Card.Body>
            {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
              <Spinner animation="border" variant="primary" />
            </div>}
            {isLoaded && userProfile !== null && <>
              <h3>User Information</h3>
              <Table bordered>

                <tbody>
                  <tr>
                    <td>
                      <strong>Name</strong>
                    </td>
                    <td>{userProfile.user.name_first} {userProfile.user.name_last}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Nickname</strong>
                    </td>
                    <td>{userProfile.user.nickname} <Button className="btn-sm"
                      onClick={() => {
                        setChangeInfoModalOpen(true);
                      }}
                    >Change</Button></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email</strong>
                    </td>
                    <td>{userProfile.user.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Registered</strong>
                    </td>
                    <td>{new Date(userProfile.user.date_registered).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Country</strong>
                    </td>
                    <td>{userProfile.user.country}</td>
                  </tr>
                </tbody>
              </Table>
              <h3>Plan</h3>
              {userProfile.subscription === null || userProfile.subscription === undefined && <>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Plan</strong>
                      </td>
                      <td>Eduerity Free</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Maximum Private Quizes</strong>
                      </td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="d-grid gap-2">
                          <Button variant="primary" onClick={() => {
                            setUpgradePlanModalOpen(true);
                          }}>
                            Upgrade Plan
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>}
              {!!userProfile.subscription && <>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Plan</strong>
                      </td>
                      <td>{userProfile.subscription.name}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Start Date</strong>
                      </td>
                      <td>{new Date(userProfile.subscription.date_added).toLocaleDateString()}</td>
                    </tr>
                    {/* ensure date_current_period_end is not null (ie free) */}
                    {userProfile.subscription.date_current_period_end !== null && <tr>
                      <td>
                        <strong>Next Billing Date</strong>
                      </td>
                      <td>{new Date(userProfile.subscription.date_current_period_end).toLocaleDateString()}</td>
                    </tr>}
                  </tbody>
                </Table>
              </>}
            </>}




          </Card.Body>
        </Card>

      </Col>
      <Col>
        <Card>
          <Card.Title>
            <h3>My Invites</h3>
            <hr />
          </Card.Title>
          <Card.Body>
            {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
              <Spinner animation="border" variant="primary" />
            </div>}
            {isLoaded && <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Redeemed</th>
                    <th>Redeemed Status</th>
                  </tr>
                </thead>
                <tbody>
                  {inviteCodes.sort((a, b) => { return a.redeemed ? -1 : 1 }).map((result, index) => {
                    return <tr key={result.sharecode}>
                      <td>{result.sharecode}</td>
                      <td>{result.redeemed ? 'Yes' : 'No'}</td>
                      <td>{result.redeemed ?
                        <>{result.invited_user_name} at {new Date(result.date_created).toLocaleString()}</>
                        : <> <Button
                          variant="primary"
                          id="button-addon2"
                          onClick={() => {
                            copy(result.sharecode);
                            toast.success("Code copied");
                          }}
                        >
                          Copy the code
                        </Button> </>}</td>
                    </tr>
                  })}
                </tbody>
              </Table>
            </>}

          </Card.Body>
        </Card>

      </Col>
    </Row>





  </>;
};
