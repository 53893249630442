import { useEffect, useState } from "react";
import { Alert, Button, FormControl, InputGroup, ListGroup } from "react-bootstrap";
import { MultipleChoiceAnswer, MultipleChoiceQuestion, QuizMeta, QuizResultsDisplayMode, TextQuestion, UserAnswerMultipleChoice, UserAnswerText } from "../quiz-types";
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { validateTextAnswer } from "../validators/textanswer";

import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'


interface Props {
  question: TextQuestion
  questionIndex: number
  doSubmitAnswer(question: TextQuestion, answers: UserAnswerText): void
  goToNext(): void
  isAnswered: boolean
  hasTimeout: boolean
  quizMeta: QuizMeta
}

export const TextQuestionForm = (props: Props) => {

  const { question } = props;

  const [userInput, setUserInput] = useState<string>("");

  const submitAnswer = () => {
    props.doSubmitAnswer(question, { questionId: question.id, answer: userInput, timeout: false });
  }

  const goToNext = () => {
    setUserInput(''); // reset select ids for next q
    props.goToNext();
  }

  return <>
  <ReactMarkdown className='app-markdown'
      // @ts-ignore
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
              
    >{question.questionBody}</ReactMarkdown>
    {question.katexQuestionBody !== '' && <TeX math={question.katexQuestionBody} block />}

  {/* Handle user input */}
  <ListGroup>

    <br />
      {props.hasTimeout && <>
        <strong>Failed (timeout)</strong>
      </>}
      {props.quizMeta.results_display_mode === QuizResultsDisplayMode.Immediately && props.isAnswered && <>
        <strong>Answer:</strong>
        <ReactMarkdown className='app-markdown'
          // @ts-ignore
          remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >{question.solutionExplanation}</ReactMarkdown>
        {question.solutionExplanationKatex !== '' && <TeX math={question.solutionExplanationKatex} block />}
        <br />
      </>}

      {/*  */}
      <h4>Answer</h4>
      <InputGroup className="mb-3">
        <InputGroup>
          <InputGroup.Text
            id="inputGroup-sizing-default"
            style={{ minWidth: 250 }}
          >Answer</InputGroup.Text>
        </InputGroup>
        <FormControl
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          value={userInput}
          onChange={(e) => { setUserInput(e.target.value); }}
          disabled={props.isAnswered} 
        />
      </InputGroup>

      {props.quizMeta.results_display_mode === QuizResultsDisplayMode.Immediately && props.isAnswered && <>
        {validateTextAnswer(props.question.answer, userInput) ?
          <Alert variant="success">
            <strong>Correct!</strong>
          </Alert>
        : 
          <Alert variant="danger">
            <strong>Incorrect!</strong>
          </Alert>
        }
      </>}

      {!props.isAnswered && <Button variant="primary" onClick={submitAnswer}>Submit answer</Button>}
      {props.isAnswered && <Button variant="secondary" onClick={goToNext}>Next question</Button>}
      </ListGroup>
  </>;
}