import { FormControl, InputGroup } from "react-bootstrap";
import { Question, TextQuestion } from "../quiz-types";

interface Props {
  question: TextQuestion
  questionIndex: number
  updateQuestion(question: Question): void
}

export const EditTextQuestion = (props: Props) => {

  const { question, questionIndex, updateQuestion } = props;

  const updateAnswer = (answer: string) => {
    updateQuestion({
      ...question,
      answer
    });
  }

  return <>
    <h4>Answer</h4>
    <InputGroup className="mb-3">
      <InputGroup>
        <InputGroup.Text
          id="inputGroup-sizing-default"
          style={{ minWidth: 250 }}
        >Answer</InputGroup.Text>
      </InputGroup>
      <FormControl
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        value={question.answer}
        onChange={(e) => { updateAnswer(e.target.value); }}
      />
    </InputGroup>
  </>;
}