import { useEffect, useState } from "react";
import { Alert, Button, FormControl, InputGroup, ListGroup } from "react-bootstrap";
import { FlashCardQuestion, MultipleChoiceAnswer, MultipleChoiceQuestion, QuizMeta, QuizResultsDisplayMode, TextQuestion, UserAnswerFlashCard, UserAnswerMultipleChoice, UserAnswerText } from "../quiz-types";
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'


interface Props {
  question: FlashCardQuestion
  questionIndex: number
  doSubmitAnswer(question: FlashCardQuestion, answers: UserAnswerFlashCard): void
  goToNext(): void
  isAnswered: boolean
  hasTimeout: boolean
  quizMeta: QuizMeta
}

export const FlashCardQuestionForm = (props: Props) => {

  const { question } = props;

  const [showBackside, setShowBackside] = useState<boolean>(false);


  const submitAnswer = (knewAnswer: boolean) => {
    props.doSubmitAnswer(question, { questionId: question.id, answer: knewAnswer, timeout: false });
  }

  const goToNext = () => {
    setShowBackside(false);
    props.goToNext();
  }

  return <>
  <ReactMarkdown className='app-markdown'
      // @ts-ignore
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      >{question.questionBody}</ReactMarkdown>
    {question.katexQuestionBody !== '' && <TeX math={question.katexQuestionBody} block />}

  {/* Handle user input */}
  <ListGroup>

    <br />
      {props.hasTimeout && <>
        <strong>Failed (timeout)</strong>
      </>}
      {(showBackside || props.isAnswered) && <>
        <strong>Answer:</strong>
        <ReactMarkdown className='app-markdown'
          // @ts-ignore
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
          
          >{question.solutionExplanation}</ReactMarkdown>
        {question.solutionExplanationKatex !== '' && <TeX math={question.solutionExplanationKatex} block />}
        <br />
      </>}

      {!showBackside && !props.isAnswered && <Button variant="primary" onClick={() => {
        setShowBackside(true);
      }}><i className="bi bi-arrow-up-right-square-fill"></i> Flip card (see backside)</Button>}


      {showBackside && !props.isAnswered && <>
        <Button variant="success" onClick={() => {
          submitAnswer(true);
        }}><i className="bi bi-check-lg"></i> I Knew the answer</Button>
        <Button variant="danger" onClick={() => {
          submitAnswer(false);
        }}><i className="bi bi-x-lg"></i> Did not know</Button>
      </>}
      {props.isAnswered && <Button variant="secondary" onClick={goToNext}>Next question</Button>}
      </ListGroup>
  </>;
}