import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { getAPIPath } from "../core";
import { ServerUserQuizResults } from "../quiz-types";
import { userAccessTokenState, userProfileState } from "../state/mainstate";

export const MyLearning = () => {

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [myQuizResults, setMyQuizResults] = useState<ServerUserQuizResults[]>([]);

  useEffect(() => {
    loadMyLearning();
  }, []);

  const loadMyLearning = async () => {
    setIsLoading(true);
    const myLearningResp = await fetch(getAPIPath() + '/api/quiz/quiz/me/learning', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (myLearningResp.status !== 200) {
      toast.error("Failed to load");
      return;
    }


    const resultsList = await myLearningResp.json();

    setMyQuizResults(resultsList.quizResults);

    setIsLoading(false);
    setIsLoaded(true);

  }


  return <>

    <Row>
      <Col>


        <Card>
          <Card.Title>
            <h3>My Results</h3>
            <hr />
          </Card.Title>
          <Card.Body>
            {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
              <Spinner animation="border" variant="primary" />
            </div>}
            {isLoaded && <>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Quiz</th>
                    <th>Correct/Total</th>
                    <th>Score</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  <tbody>
                    {myQuizResults.map((result, index) => {
                      return <tr key={result.sharecode}>
                        <td> <Link to={`/quiz/${result.sharecode}`}>{result.name}</Link></td>
                        <td>{result.num_correct}/{result.num_questions}</td>
                        <td>{result.num_pct}%</td>
                        <td>{new Date(result.date_created).toLocaleString()}</td>
                      </tr>
                    })}
                  </tbody>
              </Table>
                </>}  

              </Card.Body>
            </Card>

      </Col>
          <Col></Col>
        </Row>





      </>;
};
