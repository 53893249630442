import { QuizAccessibilityLevel, QuizControlFlowLevel, QuizResultsDisplayMode } from "./quiz-types";

export const getAccessibilityName = (accessibilityLevel: QuizAccessibilityLevel) => {
  switch (accessibilityLevel) {
    case QuizAccessibilityLevel.Public:
      return "Public";
    case QuizAccessibilityLevel.Unlisted:
      return "Unlisted";
    case QuizAccessibilityLevel.Private:
      return "Private";
  }
};

export const getControlFlowName = (controlFlowLevel: QuizControlFlowLevel) => {
  switch (controlFlowLevel) {
    case QuizControlFlowLevel.StrictChronological:
      return "Chronological (Strict)";
    case QuizControlFlowLevel.RelaxedFreeOrder:
      return "Free Order (Relaxed)";
  }
}

export const getResultsDisplayName = (resultsDisplayLevel: QuizResultsDisplayMode) => {
  switch (resultsDisplayLevel) {
    case QuizResultsDisplayMode.Immediately:
      return "Immediately";
    case QuizResultsDisplayMode.AtEnd:
      return "At End";
  }
}