import { Editor, EditorState, RichUtils } from 'draft-js';
import { useEffect, useState } from 'react';


interface Props {
  onStartEdit(blockKey: string): void
  onFinishEdit(blockKey: string, newContentState: EditorState): void
  onRemove(blockKey: string): void
  contentState: any
  block: any
  blockProps: any
  editorRef: any
}


export const ImageBlock = (props: Props) => {

  const [editMode, setEditMode] = useState(false);
  const [imgSrc, setImgSrc] = useState<string|undefined>('');
  const [newContentStateSave, setNewContentStateSave] = useState<any>(null);
  
  const [doSave, setDoSave] = useState(false);

  const getValue = () => {
    return props.contentState.getEntity(props.block.getEntityAt(0)).getData()['content'];
  }

  const finishEdit = (newContentState: any) => {
    props.blockProps.onFinishEdit(
      props.block.getKey(),
      newContentState,
    );
  }


  const save = () => {
    const entityKey = props.block.getEntityAt(0);
    const newContentState = props.contentState.mergeEntityData(entityKey, { content: imgSrc });
    setEditMode(false);
    setImgSrc('');
    setNewContentStateSave(newContentState);
    setDoSave(true);
  }

  useEffect(() => {
    console.log('doSave er nå', doSave);
    if (doSave) {
      console.log('finish edit!!!');
      finishEdit(newContentStateSave); 
      console.log(props.editorRef);
      setDoSave(false);
      // (props.editorRef.current as any).focus();
    }

  }, [doSave]);


  const remove = () => {
    props.blockProps.onRemove(props.block.getKey());
  }

  const startEdit = () => {
    props.blockProps.onStartEdit(props.block.getKey());
  }

  const onClick = () => {

    if (editMode) return;
    setEditMode(true);
    setImgSrc(getValue());
    startEdit();

  };

  const onValueChange = (evt: any) => {
    var value = evt.target.value;
    setImgSrc(value);
      // try {
      //   katex.renderToString(value);
      // } catch (e) {
      //   invalid = true;
      // } finally {
      //   setInvalidTeX(invalid);
      //   setTexValue(value);
      // }
  }

  var imgContent = null;
  if (editMode) {
    imgContent = imgSrc;
    // if (invalidTeX) {
    //   texContent = '';
    // } else {
    //   texContent = texValue;
    // }
  } else {
    imgContent = getValue();
  }

  var className = 'ImgEditor-img';
  if (editMode) {
    className += ' ImgEditor-activeImg';
  }

  var editPanel = null;
  if (editMode) {
    var buttonClass = 'ImgEditor-saveButton';
   
    // TODO: Reconsider the positioning / styling of this component
    editPanel =
      <div className="ImgEditor-panel" style={{position: 'absolute', bottom: 0, left: 0, marginBottom: -100, zIndex: 5}}>
        <textarea
          className="ImgEditor-imgValue"
          onChange={onValueChange}
          // ref="textarea"
          value={imgSrc}
        />
        <div className="ImgEditor-buttons">
          <button
            className={buttonClass}
            disabled={false}
            onClick={save}>
            {/* {invalidTeX ? 'Invalid TeX' : 'Done'} */}
            Done
          </button>
          <button className="ImgEditor-removeButton" onClick={remove}>
            Remove
          </button>
        </div>
      </div>;
  }

  return (
    <div className={className}>
      {/* <KatexOutput content={texContent} onClick={this._onClick} /> */}
      {/* <TeX math={texContent} onClick={onClick}  /> */}
      <img src={imgContent} onClick={onClick} />
      {editPanel}
    </div>
  );
}


  // <TeX math={question.katexQuestionBody} block />
  // return <div> <TeX math={question.katexQuestionBody} onClick={} /> </div>
