import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Button, ButtonGroup, Card, Col, ListGroup, OverlayTrigger, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AddToCollectionPopover } from "./Collection/AddToCollectionPopover";
import { getAPIPath } from "./core";
import { getAccessibilityName, getControlFlowName, getResultsDisplayName } from "./domain.util";
import { AddToCollection } from "./Modals/AddToCollection";
import { ShareQuiz } from "./Modals/ShareQuiz";
import { QuizAccessibilityLevel, ServerAuthor, ServerLeagueTableEntry, ServerQuizCategory, ServerQuizFull, ServerQuizLight, ServerSubscriber, ServerUserQuizResults } from "./quiz-types";
import { activeQuiz, activeQuizMeta, userAccessTokenState, userProfileState } from "./state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "./state/share";
import { getUserColor } from "./user";

export const ViewQuizDetails = () => {

  const sharecode = useParams().code;

  const navigate = useNavigate();

  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const setShareCodeOpen = useSetRecoilState(activeShareWindowState);
  const setShareQuiz = useSetRecoilState(activeShareQuizState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);


  const [quizData, setQuizData] = useState<ServerQuizFull | null>(null);
  const [authors, setAuthors] = useState<ServerAuthor[]>([]);
  const [subscribers, setSubscribers] = useState<ServerSubscriber[]>([]);
  const [leagueTable, setLeagueTable] = useState<ServerLeagueTableEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [myQuizResults, setMyQuizResults] = useState<ServerUserQuizResults[]>([]);

  const [addToCollectionModal, setAddToCollectionModal] = useState(false);

  const [categories, setCategories] = useState<ServerQuizCategory[]>([]);


  useEffect(() => {

    const loadQuizDetails = async () => {

      setIsLoading(true);

      const quizDetailsResp = fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}`, {
        headers: {
          "Authorization": `Bearer ${userAccessToken}`
        }
      });

      const quizLeagueTableResp = fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}/league`, {
        headers: {
          "Authorization": `Bearer ${userAccessToken}`
        }
      });

      const quizSubscribersResp = fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}/subscribers`, {
        headers: {
          "Authorization": `Bearer ${userAccessToken}`
        }
      });

      const quizUserLearningResp = fetch(getAPIPath() + `/api/quiz/quiz/me/learning/${sharecode}`, {
        headers: {
          "Authorization": `Bearer ${userAccessToken}`
        }
      });

      const quizCategoryResp = fetch(getAPIPath() + `/api/quiz/categories`, {
        headers: {
          "Authorization": `Bearer ${userAccessToken}`
        }
      });

      const [quizDetailsRespDone, quizUserLearningRespDone, quizSubscribersRespDone, quizLeagueTableRespDone, quizCategoryRespDone] = await Promise.all([quizDetailsResp, quizUserLearningResp, quizSubscribersResp, quizLeagueTableResp, quizCategoryResp]);

      if (quizDetailsRespDone.status !== 200) {
        toast.error("Quiz not found");
        return;
      }

      if (quizUserLearningRespDone.status !== 200) {
        toast.error("An error occured");
        return;
      }

      if (quizSubscribersRespDone.status !== 200) {
        toast.error("An error occured");
        return;
      }

      if (quizLeagueTableRespDone.status !== 200) {
        toast.error("An error occured");
        return;
      }

      if (quizCategoryRespDone.status !== 200) {
        toast.error("An error occured");
        return;
      }

      // Set quiz data
      const quizData = await quizDetailsRespDone.json();

      setQuizData(quizData.quiz);

      // Set quiz meta
      setQuizMeta({
        category_parent_id: quizData.quiz.category_parent_id,
        category_sub_id: quizData.quiz.category_sub_id,
        accessibility: quizData.quiz.accessibility,
        control_flow: quizData.quiz.control_flow,
        results_display_mode: quizData.quiz.results_display_mode,
      });

      setQuiz(quizData.quiz.content);
      setAuthors(quizData.authors);

      // Set results data
      const quizUserLearningData = await quizUserLearningRespDone.json();
      setMyQuizResults(quizUserLearningData.quizResults);

      // Set subscribers data
      const quizSubscribersData = await quizSubscribersRespDone.json();
      setSubscribers(quizSubscribersData.subscribers);

      // Set league table data
      const quizLeagueTableData = await quizLeagueTableRespDone.json();
      setLeagueTable(quizLeagueTableData.leagueTable);

      // Set categories data
      const quizCategoryData = await quizCategoryRespDone.json();
      setCategories(quizCategoryData.categories);

      setIsLoading(false);
      setIsLoaded(true);
    };

    loadQuizDetails();

  }, []);

  const makeCoAuthor = async (user: ServerSubscriber) => {

    const resp = await fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}/coauthor`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${userAccessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        newCoAuthorId: user.user_id
      })
    });

    if (resp.status !== 200) {
      toast.error("An error occured");
      return;
    }

    const data = await resp.json();

    // update co-authors list
    const authorCop: ServerAuthor[] = JSON.parse(JSON.stringify(authors));

    authorCop.push({
      user_id: user.user_id,
      nickname: user.nickname
    });

    setAuthors(authorCop);

    toast.success("Co-author added");

  }

  const remveCoAuthor = async (user: ServerAuthor) => {

    const resp = await fetch(getAPIPath() + `/api/quiz/quiz/${sharecode}/coauthor/${user.user_id}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${userAccessToken}`,
        "Content-Type": "application/json"
      },
    });

    if (resp.status !== 200) {
      toast.error("An error occured");
      return;
    }

    const data = await resp.json();

    // update co-authors list
    const authorCop: ServerAuthor[] = JSON.parse(JSON.stringify(authors));

    authorCop.splice(authorCop.indexOf(user), 1);

    setAuthors(authorCop);

    toast.success("Co-author removed");


  };

  return <>
    <ShareQuiz />
    <AddToCollection 
      show={addToCollectionModal} 
      onHide={() => { setAddToCollectionModal(false); }} 
      quizSharecode={sharecode}  
    />
    {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
      <Spinner animation="border" variant="primary" />
    </div>}
    {quiz !== null && quizData !== null && quizMeta !== null &&<>
      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Quiz: {quiz.title}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                  <hr />
                  <Card.Text>
                    <p>{quiz.description}</p>
                    <span><strong>Default timelimit</strong>: {quiz.defaultTimeLimit} seconds</span><br />
                    <span><strong>Overall timelimit</strong>: {quiz.overallTimeLimit === 0 ? 'infinite' : <>{quiz.overallTimeLimit} seconds</>} </span><br />
                    <span><strong>Order</strong>:</span> {getControlFlowName(quizMeta.control_flow)}<br />
                    <span><strong>Num Questions</strong>: {quiz.questions.length}</span><br />
                    <span><strong>Category</strong>: {categories.find((el) => { return el.id === quizMeta.category_parent_id })?.name} / {categories.find((el) => { return el.id === quizMeta.category_sub_id })?.name}</span><br />
                    <span><strong>Visibility</strong>: {getAccessibilityName(quizMeta.accessibility)} {quizMeta.accessibility === QuizAccessibilityLevel.Public && <span style={{marginLeft: 5}}>
                      <i className="bi bi-people-fill" style={{color: '#757575'}}></i>
                      </span>} </span><br />
                    <span><strong>Display Results</strong>: {getResultsDisplayName(quizMeta.results_display_mode)}</span>
                  </Card.Text>
                  <ButtonGroup aria-label="Basic example" style={{position: 'relative'}}>
                    <Button onClick={() => {
                      navigate(`/quiz/${sharecode}/take`);
                    }}><i className="bi bi-play"></i> <strong>Take</strong></Button>

                    {/* {props.type === PublicBrowserType.Mine && <Button variant="dark" onClick={() => {
                  navigate(`/quiz/${quiz.sharecode}/edit`);
                }}><i className="bi bi-pencil"></i> <strong>Edit</strong></Button>} */}

                    {userProfile !== null && (
                        userProfile.user.localid === quizData.creator ||  
                        authors.find(author => author.user_id === userProfile.user.localid) !== undefined
                        ) &&
                      <Button variant="dark" onClick={() => {
                        navigate(`/quiz/${sharecode}/edit`);
                      }}><i className="bi bi-pencil"></i> <strong>Edit</strong></Button>
                    }

                    {/* Add to collection */}
                    <Button onClick={() => {
                      setAddToCollectionModal(true);
                    }}>Add to collection</Button>

                    <Button variant="dark" onClick={() => {
                      setShareCodeOpen(true);
                      setShareQuiz(quizData);
                    }}><i className="bi bi-share"></i> Share</Button>

                  </ButtonGroup>

             


                  <ButtonGroup aria-label="Basic example">
                    {/* <Button variant="dark" onClick={() => {
                  // confirm that user wants to unsubscribe
                  if (!window.confirm("Are you sure you want to unsubscribe from this quiz?")) return;
                  unsubscribeFromQuiz(quiz);
                }}><i className="bi bi-trash"></i></Button> */}
                  </ButtonGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <hr />

          {/* Authors */}
          {quizData !== null && <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Authors</Card.Title>
                  <ListGroup>
                    {authors.sort((a, b) => {
                      return a.user_id === quizData.creator ? -1 : 1;
                    }).map((author, index) => {
                      return <ListGroup.Item key={`author-${author.user_id}`}>
                        <Row>
                          <Col>
                            <Avatar name={author.nickname} size="50" round={true} color={getUserColor(author.nickname)} />
                            <span>{author.nickname}
                              {author.user_id === quizData.creator && <span> (<strong>creator</strong>)</span>}
                            </span>
                          </Col>
                          <Col style={{ textAlign: 'right' }}>

                            {/* Only show btn to users not authors */}
                            {userProfile?.user.localid === quizData.creator && author.user_id !== quizData.creator && <Button variant="dark" onClick={() => {
                              remveCoAuthor(author);
                            }}>
                              Remove co-author
                            </Button>}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    })}
                  </ListGroup>
                </Card.Body>
                {/* <Card.Footer className="text-muted">
                  <span style={{ marginLeft: 10, marginRight: 10 }}>Quiz by</span>
                  <Avatar round={true} name={quizData.creator.toString()} color={getUserColor(quizData.creator.toString())} size="30"
                    textSizeRatio={2}
                  />
                  <span style={{ marginLeft: 10, marginRight: 10 }}>{quizData.creator.toString()}</span>
                </Card.Footer> */}
              </Card>

            </Col>
          </Row>}

          <hr />

          {/* Subscribers */}
          {quizData !== null && <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title>Subscribers</Card.Title>
                  <ListGroup>
                    {subscribers.map((subscriber, index) => {
                      return <ListGroup.Item key={index}>

                        <Row>
                          <Col>
                            <Avatar name={subscriber.nickname} size="50" round={true} color={getUserColor(subscriber.nickname)} />
                            <span>
                              <Link to={`/profile/${subscriber.sharecode}`}>{subscriber.nickname}</Link>
                            </span>
                          </Col>
                          <Col style={{ textAlign: 'right' }}>

                            {/* Only show btn to users not authors */}
                            {userProfile?.user.localid === quizData.creator && authors.find(author => author.user_id === subscriber.user_id) === undefined && <Button variant="dark" onClick={() => {
                              makeCoAuthor(subscriber);
                            }}>
                              Make co-editor
                            </Button>}



                          </Col>
                        </Row>


                      </ListGroup.Item>
                    })}
                  </ListGroup>
                </Card.Body>
                {/* <Card.Footer className="text-muted">
                  <span style={{ marginLeft: 10, marginRight: 10 }}>Quiz by</span>
                  <Avatar round={true} name={quizData.creator.toString()} color={getUserColor(quizData.creator.toString())} size="30"
                    textSizeRatio={2}
                  />
                  <span style={{ marginLeft: 10, marginRight: 10 }}>{quizData.creator.toString()}</span>
                </Card.Footer> */}
              </Card>

            </Col>
          </Row>}


        </Col>
        <Col sm={12} md={6}>
          
          <Row>
            <Col><Card>

<Card.Body>
  <Card.Title>
    My Results
  </Card.Title>
  <hr />
  {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
    <Spinner animation="border" variant="primary" />
  </div>}
  {isLoaded && <>
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Quiz</th>
          <th>Correct/Total</th>
          <th>Score</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {myQuizResults.map((result, index) => {
          return <tr key={result.sharecode}>
            <td> <Link to={`/quiz/${result.sharecode}`}>{result.name}</Link></td>
            <td>{result.num_correct}/{result.num_questions}</td>
            <td>{result.num_pct}%</td>
            <td>{new Date(result.date_created).toLocaleString()}</td>
          </tr>
        })}
      </tbody>
    </Table>
  </>}

</Card.Body>
</Card></Col>
          </Row>
          <hr />
          <Row>
            <Col>
            <Card>

            <Card.Body>
              <Card.Title>
                League Tables
              </Card.Title>
              <hr />
              {isLoading && <div style={{ textAlign: 'center', width: '100%' }}>
                <Spinner animation="border" variant="primary" />
              </div>}
              {isLoaded && <>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>User</th>
                      <th>League Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leagueTable.map((result, index) => {
                      return <tr key={result.sharecode}>
                        <td>{index+1}</td>
                        <td> <Link to={`/profile/${result.sharecode}`}>{result.nickname}</Link></td>
                        <td>{result.num_pct}%</td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </>}

              <i>You need at least three quiz entries to be appear in the league tables.</i>
            </Card.Body>
          </Card>
            </Col>
          </Row>

          

        </Col>
      </Row>
    </>}
  </>;
};