import { useEffect, useState } from "react";
import { Accordion, Button, ButtonGroup, Spinner, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { getAPIPath } from "../core";
import { ServerCollection } from "../quiz-types";
import { userAccessTokenState } from "../state/mainstate";
import { useNavigate } from 'react-router';
import { Link } from "react-router-dom";

interface QuizInCollection {
  id: number,
  name: string,
  sharecode: string,
  meta_questions_count: number
}

export const CollectionBrowser = () => {

  const navigate = useNavigate();

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [myCollections, setMyCollections] = useState<ServerCollection[]>([]);
  const [quizesInCollection, setQuizesInCollection] = useState<QuizInCollection[]>([]);

  const [loadingCollections, setLoadingCollections] = useState(false);
  const [loadingActiveCollection, setLoadingActiveCollection] = useState(false);

  const loadMyCollections = async () => {
    setLoadingCollections(true);

    const resp = await fetch(getAPIPath() + '/api/collection/me', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (resp.status !== 200) {
      toast.error("Failed to load collections");
      setLoadingCollections(false);
      return;
    }

    const collections = await resp.json();
    setMyCollections(collections.collections);
    setLoadingCollections(false);
  }

  const loadActiveCollection = async (collection: ServerCollection) => {
    setLoadingActiveCollection(true);

    const resp = await fetch(getAPIPath() + '/api/collection/' + collection.sharecode + '/quiz', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (resp.status !== 200) {
      toast.error("Failed to load collection");
      setLoadingActiveCollection(false);
      return;
    }

    const collectionData = await resp.json();
    setQuizesInCollection(collectionData.quizes);
    setLoadingActiveCollection(false);
  }

  const createCollection = async () => {


    const name = prompt("Enter a name for the collection");
    if (!name) {
      toast.info("Collection creation cancelled");
      return;
    }

    const loadingToast = toast.info("Creating collection...", {
      autoClose: false
    });


    // Create a new collection with the specificied name
    const resp = await fetch(getAPIPath() + '/api/collection', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name
      })
    });

    if (resp.status !== 200) {
      toast.dismiss(loadingToast);
      const errRespData = await resp.json();
      toast.error(errRespData.status === false ? errRespData.message : "Failed to create collection");
      return;
    }

    const collection = await resp.json();

    // Trigger re-load of collections
    loadMyCollections();

    // Inform of successfull creation
    toast.dismiss(loadingToast);
    toast.success("Collection created");

  }

  const removeFromCollection = async (collection: ServerCollection, quiz: QuizInCollection) => {
    const resp = await fetch(getAPIPath() + '/api/collection/' + collection.sharecode + '/quiz/' + quiz.sharecode, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (resp.status !== 200) {
      toast.error("Failed to remove quiz from collection");
      return;
    }

    // Trigger re-load of collections
    loadActiveCollection(collection);
  
    // Send a message
    toast.success("Quiz removed from collection");

  }

  const removeCollection = async (collection: ServerCollection) => {

    // Confirm that the user wants to remove the collection 
    if (!window.confirm("Are you sure you want to remove this collection?")) {
      return;
    }

    const resp = await fetch(getAPIPath() + '/api/collection/' + collection.sharecode, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (resp.status !== 200) {
      toast.error("Failed to remove collection");
      return;
    }

    // Trigger re-load of collections
    loadMyCollections();

    // Send a message
    toast.success("Collection removed");

  }


  useEffect(() => {
    loadMyCollections();
  }, []);

  return <>
    <h3>Your collections</h3>
    <hr />
    <Accordion>
      {loadingCollections && <div style={{ textAlign: 'center', width: '100%' }}>
        <Spinner animation="border" variant="primary" />
      </div>}
      {myCollections.map((collection: any, index: any) => {
        return <Accordion.Item eventKey={collection.id} key={collection.id}>
          <Accordion.Header onClick={(e) => {
            loadActiveCollection(collection);
          }}>
            <h5>{collection.name}</h5>
          </Accordion.Header>
          <Accordion.Body>
            {loadingActiveCollection && <div style={{ textAlign: 'center', width: '100%' }}>
              <Spinner animation="border" variant="primary" />
            </div>}

            <Table striped hover>
              <thead>
                <tr className="d-flex">
                  <th className="col-xl-7 col-sm-6 col-7">Name</th>
                  <th className="col-xl-1 col-sm-2 d-none d-sm-table-cell">Questions</th>
                  <th className="col-xl-4 col-sm-4 col-5"></th>
                </tr>
              </thead>
              <tbody>
                {quizesInCollection.map((quiz: any) => {
                  return <tr className="d-flex">
                    <td className="col-xl-7 col-sm-6 col-7">
                      <Link to={`/quiz/${quiz.sharecode}`}>{quiz.name}</Link>
                    </td>
                    <td className="col-xl-1 col-sm-2 d-none d-sm-table-cell">{quiz.meta_questions_count}</td>
                    <td className="col-xl-4 col-sm-4 col-5">
                      <ButtonGroup aria-label="Basic example">
                        <Button onClick={() => {
                          navigate(`/quiz/${quiz.sharecode}/take`);
                        }}><i className="bi bi-play"></i>
                          <span className="d-none d-lg-inline-block"><strong>Take</strong></span>
                        </Button>
                        <Button variant="secondary" onClick={() => {
                          removeFromCollection(collection, quiz);
                        }}><i className="bi bi-trash"></i>
                          <span className="d-none d-lg-inline-block">Remove</span>
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>

            <hr />
            <Button variant="danger" onClick={() => {
              removeCollection(collection);
            }}>Delete collection</Button>

          </Accordion.Body>
        </Accordion.Item>
      })}
    </Accordion>
    <hr />
    <Button onClick={createCollection}>Create new collection</Button>
  </>;
}

