import { useState } from "react";
import { Alert, Button, Col, ListGroup, Row } from "react-bootstrap";
import { isMultipleChoiceQuestion, isTextQuestion, MultipleChoiceAnswer, MultipleChoiceQuestion, TextQuestion, Question, Quiz, UserAnswer, UserAnswerMultipleChoice, UserAnswerText, isFlashCardQuestion, FlashCardQuestion, UserAnswerFlashCard } from "../quiz-types";
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { validateTextAnswer } from "../validators/textanswer";
import { calculateUserQuizResult } from "./util";

import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'


interface Props {
  quiz: Quiz,
  userAnswers: UserAnswer[]
  resetProgress(): void
}

export const QuizResults = (props: Props) => {

  const { quiz, userAnswers } = props;
  const [showResults, setShowResults] = useState(false);

  console.log('QuizResults', quiz, userAnswers);

  const { numCorrect,
    numAns,
    numAnsPct } = calculateUserQuizResult(quiz, userAnswers);


  const renderMultipleChoiceResults = (questionObj: MultipleChoiceQuestion, index: number, userAnswerInQuestion: UserAnswerMultipleChoice) => {
    return <>
      <Col><strong>Correct answer(s)</strong>
        {questionObj.answers.map((answer, answerIndex) => {

          const getStyle = () => {
            const wasCorrect = questionObj.correctAnswerIds.includes(answer.id);
            if (wasCorrect) {
              return {
                backgroundColor: '#2A9D8F',
                color: 'white'
              };
            }
            else {
              return {};
            }
          }

          return <ListGroup.Item key={`question-${index}-answer-${answerIndex}`} style={getStyle()}>
            <ReactMarkdown className='app-markdown'
              // @ts-ignore
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              >{answer.description}</ReactMarkdown>
            {answer.descriptionKatex !== '' && <TeX math={answer.descriptionKatex} block />}
          </ListGroup.Item>
        })}
      </Col>
      <Col><strong>Your answer(s)</strong>
        <ListGroup>
          {questionObj.answers.map((answer, answerIndex) => {

            const getStyle = () => {
              const wasSelect = userAnswerInQuestion.answerId.includes(answer.id);
              if (wasSelect) {
                return {
                  backgroundColor: '#023047',
                  color: 'white'
                };
              }
              else {
                return {};
              }
            }

            return <ListGroup.Item key={`question-${index}-answer-${answerIndex}`} style={getStyle()}>
              <ReactMarkdown className='app-markdown'
                // @ts-ignore
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                >{answer.description}</ReactMarkdown>
              {answer.descriptionKatex !== '' && <TeX math={answer.descriptionKatex} block />}
            </ListGroup.Item>
          })}
        </ListGroup>
      </Col></>;
  }

  const renderTextInputResults = (questionObj: TextQuestion, index: number, userAnswerInQuestion: UserAnswerText) => {


    const getStyle = () => {
      const wasCorrect = validateTextAnswer(questionObj.answer, userAnswerInQuestion.answer);
      if (wasCorrect) {
        return {
          backgroundColor: '#2A9D8F',
          color: 'white'
        };
      }
      else {
        return {};
      }
    }


    return <>
      <Col><strong>Correct answer</strong>
        <ListGroup><ListGroup.Item key={`question-${index}-answer-true`} style={{
          backgroundColor: '#2A9D8F',
          color: 'white'
        }}>
          {questionObj.answer}
        </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col><strong>Your answer</strong>
        <ListGroup><ListGroup.Item key={`question-${index}-answer-true`} style={getStyle()}>
          {userAnswerInQuestion.answer}
        </ListGroup.Item>
        </ListGroup>
      </Col>
    </>;
  };

  const renderFlashCardResults = (questionObj: FlashCardQuestion, index: number, userAnswerInQuestion: UserAnswerFlashCard) => {

    const getStyle = () => {
      const wasCorrect = userAnswerInQuestion.answer;
      if (wasCorrect) {
        return {
          backgroundColor: '#2A9D8F',
          color: 'white'
        };
      }
      else {
        return {
          color: 'red',
        };
      }
    }


    return <>
      <Col><strong>Your answer</strong>
        <ListGroup><ListGroup.Item key={`question-${index}-answer-true`} style={getStyle()}>
          You {userAnswerInQuestion.answer ? 'knew' : 'did not know'} the answer
        </ListGroup.Item>
        </ListGroup>
      </Col>
    </>;
  };

  return <><h2>Quiz Results</h2>
    <h3>Correct: {numCorrect} / {numAns} ({numAnsPct}%)</h3>
    <Button variant={'dark'} onClick={(e: any) => {
      props.resetProgress();
    }}>Try again</Button>
    <Button variant={'dark'} onClick={(e: any) => {
      setShowResults(!showResults);
    }}>{!showResults ? 'Show results' : 'Hide results'}</Button>
    {showResults && <>
      {quiz.questions.map((question, index) => {


        const questionObj = question as Question;
        const userAnswerInQuestion = userAnswers.find((el) => { return el.questionId === question.id; }) as UserAnswer;



        return <div key={`quiz-results-question-${question.id}`}><hr /><Row>
          <Col>
            <h4>Question {index + 1}</h4>
            <strong>Question text:</strong>
            <br />
            <ReactMarkdown className='app-markdown'
              // @ts-ignore
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              >{questionObj.questionBody}</ReactMarkdown>
            {questionObj.katexQuestionBody !== '' && <TeX math={questionObj.katexQuestionBody} block />}
            <br />
            <strong>Solution text:</strong>
            <br />
            <ReactMarkdown className='app-markdown'
              // @ts-ignore
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              >{questionObj.solutionExplanation}</ReactMarkdown>
            {questionObj.solutionExplanationKatex !== '' && <TeX math={questionObj.solutionExplanationKatex} block />}
          </Col>
        </Row>
          <Row>
            {isMultipleChoiceQuestion(questionObj) && renderMultipleChoiceResults(questionObj as MultipleChoiceQuestion, index, userAnswerInQuestion as UserAnswerMultipleChoice)}
            {isTextQuestion(questionObj) && renderTextInputResults(questionObj as TextQuestion, index, userAnswerInQuestion as UserAnswerText)}
            {isFlashCardQuestion(questionObj) && renderFlashCardResults(questionObj as FlashCardQuestion, index, userAnswerInQuestion as UserAnswerFlashCard)}
          </Row>
          {userAnswerInQuestion.timeout && <Row><Col>
            <Alert variant='danger'>Question timed out</Alert>
          </Col></Row>}
        </div>
      })}
    </>}
  </>;
}