import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Form, FormControl, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { userAccessTokenState, userProfileState } from "../state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "../state/share";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";
import { validateCountry, validateUsername } from "../validators/user";
import { getAPIPath } from "../core";
import { countryList } from "../data";

interface Props {
  closeable?: boolean
  onCloseModal?: () => void
}

export const ChangeUserInfo = (props: Props) => {

  const navigate = useNavigate();

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);

  const [isLoading, setIsLoading] = useState(false);



  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [nickname, setNickname] = useState(userProfile?.user.nickname as string);

  // useEffect(() => {

  //   const loadUserData = async () => {
  //     const userProfileResp = fetch(getAPIPath() + '/api/user/profile', {
  //       headers: {
  //         'Authorization': `Bearer ${userAccessToken}`
  //       }
  //     });
  
  //     const [userProfileDone] = await Promise.all([userProfileResp]);
  
  //     if (userProfileDone.status !== 200) {
  //       toast.error("Failed to load user information");
  //       return;
  //     }
  
  //     const userProfileData = await userProfileDone.json();
  
  //     // setUserProfile(userProfileData[0]);
  //     setUserProfile(userProfileData);
  //     setIsLoading(false);
  //   }

  //   loadUserData();

  // }, []);

  const validateForm = () => {
    return validateUsername(nickname);
  }

  const updateProfile = async () => {

    setIsLoading(true);
    const putRes = await fetch(getAPIPath() + `/api/user/profile/details`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        nickname: nickname,
      })
    });

    if (putRes.status !== 200) {
      setIsLoading(false);
      toast.error("Error updating profile");
      return;
    }

    // Update ok
    toast.success("Profile updated");

    props.onCloseModal && props.onCloseModal();
  }

  return <>
    <Modal
      show={true}
      onHide={() => {

        if (props.closeable) {
          if (props.onCloseModal !== undefined) props.onCloseModal();
        }
      }}
      backdrop="static"
      keyboard={false}
      size={'lg'}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={9}>
            <strong>Username (Display Name)</strong>
            <InputGroup>

              <FormControl
                type="text"
                aria-label="default"
                aria-describedby="inputGroup-quiz-time"
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                  // setCodeStr(e.target.value);
                }}
              // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
              />
            </InputGroup>
            <span style={{ color: 'rgb(88,88,88)' }}>3-20 characters, alphanumerical</span>
          </Col>
          <Col md={3} className='d-none d-md-block'>
            {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
            <img src="/images/profile.svg" style={{ maxWidth: 80 }} />
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="lg" style={{ width: '100%' }} disabled={
          !validateForm() && !isLoading
        } onClick={() => {
          updateProfile();
        }}>
          {isLoading && <><Spinner animation="border" variant="primary" as="span" /></>}
          {!isLoading && <>Done</>}
        </Button>

      </Modal.Footer>
    </Modal>
  </>;
}