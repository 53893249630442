import { atom, RecoilState } from "recoil";
import { ServerQuizLight } from "../quiz-types";

export const activeShareWindowState = atom({
  key: 'activeShareWindowState',
  default: false
});

export const activeShareQuizState: RecoilState<ServerQuizLight|null> = atom({
  key: 'activeShareQuizState',
  default: null as ServerQuizLight|null
});