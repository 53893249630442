import { useEffect, useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { MultipleChoiceAnswer, MultipleChoiceQuestion, QuizMeta, QuizResultsDisplayMode, UserAnswerMultipleChoice } from "../quiz-types";
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

//@ts-ignore
import seedrandom from 'seedrandom'

import { useRecoilValue } from "recoil";
import { activeSeedState } from "../state/takequiz";

import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'


interface Props {
  question: MultipleChoiceQuestion
  questionIndex: number
  doSubmitAnswer(question: MultipleChoiceQuestion, answers: UserAnswerMultipleChoice): void
  goToNext(): void
  isAnswered: boolean
  hasTimeout: boolean
  quizMeta: QuizMeta
}

export const MultipleChoiceQuestionForm = (props: Props) => {
  const { question } = props;


  const activeSeed = useRecoilValue(activeSeedState);

  const [selectIds, setSelectIds] = useState<string[]>([]);


  // Ensure that answer order is randomized for each question
  const generator = seedrandom(activeSeed + props.questionIndex);

  const answers = question.shuffleAnswers ? [...question.answers].sort((a, b) => generator() > 0.5 ? 1 : -1) : question.answers;

  const submitAnswer = () => {
    // If no answer selected, verify that user wants to submit blank
    if (selectIds.length === 0) {
      const answer = window.confirm("You have not selected an answer. Are you sure you want to submit a blank answer?");
      if (!answer) {
        return;
      }
    }
    props.doSubmitAnswer(question, { questionId: question.id, answerId: selectIds, timeout: false });
  }

  const goToNext = () => {
    setSelectIds([]); // reset select ids for next q
    props.goToNext();
  }

  return <>
    <ReactMarkdown className='app-markdown'
      // @ts-ignore
      remarkPlugins={[remarkGfm, remarkMath]}
      rehypePlugins={[rehypeKatex]}
      >{question.questionBody}</ReactMarkdown>
    {question.allowMultipleAnswers && question.allowMultipleAnswersMax !== null && <p style={{ color: '#3F3F3F', fontStyle: 'italic' }}>
      Max {question.allowMultipleAnswersMax} answers.
    </p>}
    {question.katexQuestionBody !== '' && <TeX math={question.katexQuestionBody} block />}
    <ListGroup>
      {answers.map((answer, answerIndex) => {


        const isSelected = selectIds.includes(answer.id);


        const getStyle = () => {
          const wasSelectAndCorrect = props.isAnswered && props.question.correctAnswerIds.includes(answer.id) && selectIds.includes(answer.id);
          const wasSelectAndWrong = props.isAnswered && !props.question.correctAnswerIds.includes(answer.id) && selectIds.includes(answer.id);

          // Highlight selected
          if (!props.isAnswered && isSelected) {
            return { borderTop: '1px solid white' };
          }

          // Return default style if results display mode is AtEnd
          if (props.quizMeta.results_display_mode === QuizResultsDisplayMode.AtEnd) {
            return {};
          }

          if (wasSelectAndCorrect) {
            return {
              backgroundColor: '#2A9D8F',
              color: 'white'
            };
          } else if (wasSelectAndWrong) {
            return {
              backgroundColor: '#E63946',
              color: 'white'
            };
          }
          else {
            return {};
          }
        }

        return <ListGroup.Item key={`question-${props.questionIndex}-answer-${answerIndex}`}
          active={!props.isAnswered && isSelected ? true : undefined}
          onClick={(el) => {
            if (props.isAnswered) return false;
            if (isSelected) {
              const newIds: string[] = JSON.parse(JSON.stringify(selectIds));
              const tarInd = newIds.findIndex((el) => { return el === answer.id })
              newIds.splice(tarInd, 1);
              setSelectIds(newIds);
            } else {
              // If we don't accept multiple answers, simply start a new array with select ids
              const newIds = props.question.allowMultipleAnswers ? JSON.parse(JSON.stringify(selectIds)) : [];

              // check that max answer limit reached
              if (
                props.question.allowMultipleAnswers
                && props.question.allowMultipleAnswersMax !== null
                && props.question.allowMultipleAnswersMax <= newIds.length) {
                return; // too answers reached
              }

              newIds.push(answer.id);
              setSelectIds(newIds);
            }
          }}
          style={getStyle()}
        >
          <>
            <span>Option {answerIndex + 1}</span>
            <ReactMarkdown className='app-markdown'
              // @ts-ignore
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              >{answer.description}</ReactMarkdown>
            {answer.descriptionKatex !== '' && <TeX math={answer.descriptionKatex} block />}
          </>
        </ListGroup.Item>

      })}
      <br />
      {props.hasTimeout && <>
        <strong>Failed (timeout)</strong>
      </>}
      {props.quizMeta.results_display_mode === QuizResultsDisplayMode.Immediately && props.isAnswered && <>
        <strong>Answer:</strong>
        <ReactMarkdown className='app-markdown'
          // @ts-ignore
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
          >{question.solutionExplanation}</ReactMarkdown>
        {question.solutionExplanationKatex !== '' && <TeX math={question.solutionExplanationKatex} block />}
        <br />
      </>}

      {!props.isAnswered && <Button variant="primary" onClick={submitAnswer}>Submit answer</Button>}
      {props.isAnswered && <Button variant="secondary" onClick={goToNext}>Next question</Button>}

    </ListGroup>
  </>;
}
