import { Button, ButtonGroup, Card, Dropdown, DropdownButton, FormControl, InputGroup, Pagination, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import { EditMode, FlashCardQuestion, MultipleChoiceQuestion, Question, QuestionType, Quiz, QuizAccessibilityLevel, QuizControlFlowLevel, QuizMeta, QuizResultsDisplayMode, ServerQuizCategory, ServerUserLimits, TextQuestion } from "../quiz-types";
import { activeQuiz, activeQuizMeta, editQuizModeState, userAccessTokenState, userProfileState } from "../state/mainstate";
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import { EditQuizQuestion } from "./EditQuizQuestion";
import { useEffect, useRef, useState } from "react";
import { setEditing } from "../shared";
import { useAuth0 } from "@auth0/auth0-react";
import { getAPIPath } from "../core";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

interface Props {
  createQuiz?: () => void
  updateQuiz?: (redirect: boolean, quizPayload?: Quiz) => void
}

export const EditQuiz = (props: Props) => {

  const { user } = useAuth0();

  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);

  const [editMode, setEditMode] = useRecoilState(editQuizModeState);
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);


  const latestQuiz = useRef(quiz);

  const [browseQuestion, setBrowseQuestion] = useState(0);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  // Local state editing the categories (mainCategory, subCategory)
  const [mainCategory, setMainCategory] = useState<ServerQuizCategory | null>(null);
  const [subCategory, setSubCategory] = useState<ServerQuizCategory | null>(null);

  const [categories, setCategories] = useState<ServerQuizCategory[]>([]);
  const [userLimits, setUserLimits] = useState<ServerUserLimits>({ privateQuizLimitMet: false, privateQuizesCount: 0 });

  // Get all quiz changes and use ref to prevent going stale
  useEffect(() => {
    latestQuiz.current = quiz;
  }, [quiz]);

  // On load 

  useEffect(() => {
    setEditing(true);

    const loadData = async () => {

      setLoading(true);

      const categoryResponse = await fetch(getAPIPath() + "/api/quiz/categories", {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${userAccessToken}`,
        }
      });

      const userLimits = await fetch(getAPIPath() + "/api/user/limits", {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${userAccessToken}`,
        }
      });

      if (categoryResponse.status !== 200) {
        console.log("Error loading categories");
        toast.error("Error loading categories");
        return;
      }

      if (userLimits.status !== 200) {
        console.log("Error loading user limits");
        return;
      }

      const categoryData = await categoryResponse.json();
      setCategories(categoryData.categories);

      const userLimitsData = await userLimits.json();
      setUserLimits(userLimitsData);


      setLoading(false);
      setLoaded(true);

    }

    loadData();

    return () => {
      setEditing(false);
    }

    // eslint-disable-next-line
  }, []);


  const createQuiz = () => {
    const newQuiz: Quiz = {
      title: '',
      description: '',
      defaultTimeLimit: 0,
      overallTimeLimit: 0,
      questions: []
    };
    const newMeta: QuizMeta = {
      category_parent_id: null,
      category_sub_id: null,
      accessibility: QuizAccessibilityLevel.Public,
      control_flow: QuizControlFlowLevel.StrictChronological,
      results_display_mode: QuizResultsDisplayMode.Immediately
    };
    setQuiz(newQuiz);
    setQuizMeta(newMeta);
  }

  const validateForm = () => {
    if (quizMeta === null) return;
    const { category_parent_id, category_sub_id } = quizMeta;
    const validateParentCategory = category_parent_id !== null && category_parent_id !== undefined;
    const validateSubCategory = category_sub_id !== null && category_sub_id !== undefined;
    return validateParentCategory && validateSubCategory;
  }

  const addQuestion = async (questionType: QuestionType) => {
    const quizObj = latestQuiz.current as Quiz;
    const questionsCopy = JSON.parse(JSON.stringify(quizObj.questions));

    const getEmptyMultipleChoice = (): MultipleChoiceQuestion => {
      return {
        type: 'MultipleChoiceQuestion',
        order: questionsCopy.length,
        id: uuidv4(),
        timeLimit: null,
        allMustBeCorrect: true,
        allowMultipleAnswers: false,
        allowMultipleAnswersMax: null,
        answers: [],
        correctAnswerIds: [],
        shuffleAnswers: true,
        createdBy: (user?.name as string),
        katexQuestionBody: '',
        questionBody: '',
        solutionExplanation: '',
        solutionExplanationKatex: ''
      }
    };

    const getEmptyTextQuestion = (): TextQuestion => {
      return {
        type: "TextQuestion",
        order: questionsCopy.length,
        id: uuidv4(),
        timeLimit: null,
        katexQuestionBody: '',
        questionBody: '',
        solutionExplanation: '',
        solutionExplanationKatex: '',
        answer: '',
        createdBy: (user?.name as string)
      }
    };

    const getEmptyFlashCardQuestion = (): FlashCardQuestion => {
      return {
        type: "FlashCardQuestion",
        order: questionsCopy.length,
        id: uuidv4(),
        timeLimit: null,
        katexQuestionBody: '',
        questionBody: '',
        solutionExplanation: '',
        solutionExplanationKatex: '',
        answer: false,
        createdBy: (user?.name as string)
      }
    };

    let newQuestion = null; 
    if (questionType === QuestionType.MultipleChoiceQuestion) {
      newQuestion = getEmptyMultipleChoice();
    } else if (questionType === QuestionType.TextQuestion) {
      newQuestion = getEmptyTextQuestion();
    } else if (questionType === QuestionType.FlashCardQuestion) {
      newQuestion = getEmptyFlashCardQuestion();
    }

    questionsCopy.push(newQuestion);
    await setQuiz({ ...quizObj, questions: questionsCopy });
    setBrowseQuestion(questionsCopy.length - 1);

    // Save on remove and add question
    if (props.updateQuiz !== undefined) {
      props.updateQuiz(false, quizObj); // Use Quzestionscopy because we don't bother adding the new empty question
    }
  }


  const cloneQuestion = async (question: Question) => {
    // Copy question and add id / createdBy
    const newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.id = uuidv4();
    newQuestion.createdBy = (user?.name as string);

    const quizObj = latestQuiz.current as Quiz;
    const questionsCopy = JSON.parse(JSON.stringify(quizObj.questions));

    // Add new question to questions
    questionsCopy.push(newQuestion);
    await setQuiz({ ...quizObj, questions: questionsCopy });
    setBrowseQuestion(questionsCopy.length - 1);

    // Save on remove and add question
    if (props.updateQuiz !== undefined) {
      props.updateQuiz(false, quizObj); // Use Quzestionscopy because we don't bother adding the new empty question
    }
  };

  const moveQuestionUp = (question: Question) => {
    const quizObj = latestQuiz.current as Quiz;
    const questionsCop = JSON.parse(JSON.stringify(quizObj.questions)) as Question[];

    const questionIndex = questionsCop.findIndex((el) => { return el.id === question.id; })
    if (questionIndex === -1) return;


    questionsCop.splice(questionIndex, 1);
    questionsCop.splice(questionIndex + 1, 0, question);


    // re calculate all indexes(order)
    const questionsCopAdjusted = questionsCop.map((el, index) => {
      return { ...el, order: index };
    })


    setQuiz({ ...quizObj, questions: questionsCopAdjusted });
    setBrowseQuestion(questionIndex + 1);
  };

  const moveQuestionDown = (question: Question) => {
    const quizObj = latestQuiz.current as Quiz;
    const questionsCop = JSON.parse(JSON.stringify(quizObj.questions)) as Question[];

    const questionIndex = questionsCop.findIndex((el) => { return el.id === question.id; })
    if (questionIndex === -1) return;


    questionsCop.splice(questionIndex, 1);
    questionsCop.splice(questionIndex - 1, 0, question);

    // re calculate all indexes(order)
    const questionsCopAdjusted = questionsCop.map((el, index) => {
      return { ...el, order: index };
    })

    setQuiz({ ...quizObj, questions: questionsCopAdjusted });
    setBrowseQuestion(questionIndex - 1);
  };

  const removeQuestion = (question: Question) => {
    const quizObj = latestQuiz.current as Quiz;
    const questionsCop = JSON.parse(JSON.stringify(quizObj.questions)) as Question[];

    const questionIndex = questionsCop.findIndex((el) => { return el.id === question.id; })
    if (questionIndex === -1) return;

    questionsCop.splice(questionIndex, 1);

    // re calculate all indexes(order)
    const questionsCopAdjusted = questionsCop.map((el, index) => {
      return { ...el, order: index };
    })

    const newQuiz = { ...quizObj, questions: questionsCopAdjusted };

    setQuiz(newQuiz);

    // Readjust browse question
    if (browseQuestion > 0) {
      setBrowseQuestion(browseQuestion - 1);
    }

    // Save on remove and add question
    if (props.updateQuiz !== undefined) {
      props.updateQuiz(false, newQuiz)
    }

  };

  const updateQuestion = (question: Question) => {

    const quizObj = latestQuiz.current as Quiz;

    const questionsCop = JSON.parse(JSON.stringify(quizObj.questions)) as Question[];

    const questionIndex = questionsCop.findIndex((el) => { return el.id === question.id; })
    if (questionIndex === -1) return;
    questionsCop[questionIndex] = question;

    setQuiz({ ...quizObj, questions: questionsCop });
  }




  if (quiz === null || quizMeta === null) {
    return <>
      <Card>
        <Card.Body>
          <Card.Title>No Quiz Selected</Card.Title>
          <Card.Text>
            You must either load a quiz (top right) or create a new one.<br />
            <Button onClick={createQuiz}>Create new quiz</Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </>;
  }

  let orderedQuestions: MultipleChoiceQuestion[] = JSON.parse(JSON.stringify(quiz.questions));
  orderedQuestions.sort((a, b) => {
    return a.order - b.order;
  });

  const renderPagination = () => {
    const bound = 3;
    const paginationItems = [];
    for (let i = browseQuestion - bound; i < browseQuestion + bound; i++) {
      if (i < 0) continue;
      if (i > quiz.questions.length - 1) continue;
      paginationItems.push(<Pagination.Item
        key={`pagination-${i}`}
        active={i === browseQuestion}
        onClick={() => {
          setBrowseQuestion(i);
        }}
      >{i + 1}</Pagination.Item>);
    }
    return paginationItems;
  }

  const activeQuestion = orderedQuestions[browseQuestion];


  // Show a spinner if we are loading
  if (!loaded) {
    return <div style={{ textAlign: 'center', width: '100%' }}>
      <Spinner animation="border" variant="primary" />
    </div>;
  }

  return <>
    <Card>
      <Card.Body>
        <Card.Title>Editing quiz - {quiz.title}</Card.Title>
        <InputGroup className="mb-3">
          <InputGroup>
            <InputGroup.Text
              id="inputGroup-sizing-default"
              style={{ minWidth: 250 }}
            >Title</InputGroup.Text>
          </InputGroup>
          <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={quiz.title}
            onChange={(e) => { setQuiz({ ...quiz, title: e.target.value }); }}
          />
        </InputGroup>

        <InputGroup>
          <InputGroup>
            <InputGroup.Text
              style={{ minWidth: 250 }}
            >Description</InputGroup.Text>
          </InputGroup>
          <FormControl
            as="textarea"
            aria-label="With textarea"
            value={quiz.description}
            onChange={(e) => { setQuiz({ ...quiz, description: e.target.value }); }}
          />
        </InputGroup>

        <br />

        <Row>
          <Col sm={6}>
            <strong>Visibility</strong>
            <InputGroup>

              <Form.Select aria-label="Default select example"
                value={(quizMeta.accessibility === null ? undefined : quizMeta.accessibility)}
                onChange={(e) => {
                  setQuizMeta({
                    ...quizMeta,
                    accessibility: (Number(e.target.value)) as QuizAccessibilityLevel,
                  });
                }}
              >
                <option disabled>Select visibility level</option>

                <option value={0}>Public</option>
                <option value={1} disabled={editMode === EditMode.Create && userLimits.privateQuizLimitMet}>Unlisted</option>
                <option value={2} disabled={editMode === EditMode.Create && userLimits.privateQuizLimitMet}>Private</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>


        <Row>
          <Col sm={6}>
            <strong>Order</strong>
            <InputGroup>

              <Form.Select aria-label="Default select example"
                value={quizMeta.control_flow}
                onChange={(e) => {
                  setQuizMeta({
                    ...quizMeta,
                    control_flow: (Number(e.target.value)) as QuizControlFlowLevel,
                  });
                }}
              >
                <option disabled>Select order level</option>

                <option value={QuizControlFlowLevel.StrictChronological}>Strict (Chronological Order)</option>
                <option value={QuizControlFlowLevel.RelaxedFreeOrder}>Relaxed (Free order)</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <strong>Display results</strong>
            <InputGroup>

              <Form.Select aria-label="Default select example"
                value={quizMeta.results_display_mode}
                onChange={(e) => {
                  setQuizMeta({
                    ...quizMeta,
                    results_display_mode: (Number(e.target.value)) as QuizResultsDisplayMode,
                  });
                }}
              >
                <option disabled>Select display setting</option>

                <option value={QuizResultsDisplayMode.Immediately}>Immediately</option>
                <option value={QuizResultsDisplayMode.AtEnd}>At End</option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Row>

        {userLimits.privateQuizLimitMet && <><br />
          <Alert variant="danger">
            <i className="bi bi-exclamation-triangle-fill"></i> You have reached the limit of {userLimits.privateQuizesCount} private quizzes.
            <br />
            You can <Link to={`/profile/${userProfile?.user.sharecode}/upgrade`}>upgrade</Link> your account to remove this limit.
            {/* editMode === EditMode.Create && userLimits.privateQuizLimitMet && */}
          </Alert>
        </>}

        <br />

        <Row>
          <Col sm={6}>
            <strong>Category</strong>
            <InputGroup>

              <Form.Select aria-label="Default select example"
                value={(quizMeta.category_parent_id === null ? undefined : quizMeta.category_parent_id)}
                onChange={(e) => {
                  setQuizMeta({
                    ...quizMeta,
                    category_parent_id: Number(e.target.value),
                    category_sub_id: null // reset sub category
                  });
                }}
              >
                <option>Select primary category</option>

                {categories.filter((el) => { return el.parent_category_id === null; }).sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((category, index) => {
                  return <option key={`category-${category.id}`} value={category.id}>{category.name}</option>
                })}
              </Form.Select>
              <Form.Select aria-label="Default select example"
                value={(quizMeta.category_sub_id === null ? undefined : quizMeta.category_sub_id)}
                onChange={(e) => {
                  setQuizMeta({
                    ...quizMeta,
                    category_sub_id: Number(e.target.value)
                  });
                }}
              >
                <option>Select sub category</option>
                {quizMeta.category_parent_id !== null && <>
                  {categories.filter((el) => { return el.parent_category_id === quizMeta.category_parent_id; }).sort((a, b) => { return a.name > b.name ? 1 : -1 }).map((category, index) => {
                    // Determine if the current category is the parent of the selected one
                    return <option key={`subcategory-${category.id}`} value={category.id}>{category.name}</option>
                  })}
                </>}

              </Form.Select>
            </InputGroup>
          </Col>
        </Row>

        <br />

        <Row>
          <Col sm={6}>
          <InputGroup className="mb-3">
          <InputGroup>
            <InputGroup.Text
              id="inputGroup-quiz-time"
              style={{ minWidth: 250 }}
            >Default time limit seconds (0 = <span style={{marginLeft: 5}}><i className="bi bi-infinity"></i></span>)<br /></InputGroup.Text>
          </InputGroup>
          <FormControl
            type="number"
            aria-label="default"
            aria-describedby="inputGroup-quiz-time"
            value={quiz.defaultTimeLimit}
            onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
            disabled={quizMeta.control_flow === QuizControlFlowLevel.RelaxedFreeOrder}
          />
        </InputGroup>
        {quizMeta.control_flow === QuizControlFlowLevel.RelaxedFreeOrder && <p>
          <span style={{fontStyle: 'italic'}}>Individual Question time limit disabled when using Relaxed Free Order. Use Global timer instead.</span>
        </p>}

          </Col>
          <Col sm={6}>
          <InputGroup className="mb-3">
          <InputGroup>
            <InputGroup.Text
              id="inputGroup-quiz-time"
              style={{ minWidth: 250 }}
            >Global/overeall time limit (0 = <span style={{marginLeft: 5}}><i className="bi bi-infinity"></i></span>)<br /></InputGroup.Text>
          </InputGroup>
          <FormControl
            type="number"
            aria-label="default"
            aria-describedby="inputGroup-quiz-time"
            value={quiz.overallTimeLimit}
            onChange={(e) => { setQuiz({ ...quiz, overallTimeLimit: Number(e.target.value) }); }}
          />
        </InputGroup>
          </Col>
        </Row>

      


      </Card.Body>
    </Card>

    {/* <Button variant="dark" onClick={() => {
      addQuestion(QuestionType.MultipleChoiceQuestion);
    }}><i className="bi bi-plus"></i> Add Multiple Choice question</Button>
    <Button variant="dark" onClick={() => {
      addQuestion(QuestionType.TextQuestion);
    }}><i className="bi bi-plus"></i> Add Text Input question</Button> */}


    <Row>
      <Col>
        <DropdownButton as={ButtonGroup} title={<><i className="bi bi-plus"></i> Add Question</>} id="bg-vertical-dropdown-1">
          <Dropdown.Item eventKey="1"
            onClick={() => {
              addQuestion(QuestionType.MultipleChoiceQuestion);
            }}
          ><i className="bi bi-plus"></i> Multiple Choice</Dropdown.Item>
          <Dropdown.Item eventKey="2"
            onClick={() => {
              addQuestion(QuestionType.TextQuestion);
            }}
          ><i className="bi bi-plus"></i> Text Input</Dropdown.Item>
          <Dropdown.Item eventKey="3"
            onClick={() => {
              addQuestion(QuestionType.FlashCardQuestion);
            }}
          ><i className="bi bi-plus"></i> Flashcard</Dropdown.Item>
        </DropdownButton>

        <Button variant="dark" onClick={() => {
          cloneQuestion(activeQuestion);
        }}><i className="bi bi-clipboard"></i> Clone Question</Button>
      </Col>
      <Col>
        <div style={{ textAlign: 'right' }}>
          {editMode === EditMode.Create && <>
            <Button disabled={!validateForm()} onClick={() => {
              if (props.createQuiz === undefined) return;
              props.createQuiz();
            }} variant="dark"><i className="bi bi-save"></i> Save and create</Button>
          </>}
          {editMode === EditMode.Edit && <>
            <Button onClick={() => {
              if (props.updateQuiz === undefined) return;
              props.updateQuiz(false);
            }} variant="dark"><i className="bi bi-save"></i> Save </Button>
            <Button onClick={() => {
              if (props.updateQuiz === undefined) return;
              props.updateQuiz(true);
            }} variant="dark"><i className="bi bi-save"></i> Save and exit</Button>

          </>}

        </div>

      </Col>
    </Row>






    <Pagination>
      <Pagination.First disabled={browseQuestion === 0} onClick={() => {
        setBrowseQuestion(0);
      }} />
      <Pagination.Prev disabled={browseQuestion === 0} onClick={() => {
        setBrowseQuestion(browseQuestion - 1);
      }} />

      {renderPagination()}

      <Pagination.Next disabled={browseQuestion === quiz.questions.length - 1 || quiz.questions.length === 0} onClick={() => {
        setBrowseQuestion(browseQuestion + 1);
      }} />
      <Pagination.Last disabled={browseQuestion === quiz.questions.length - 1 || quiz.questions.length === 0} onClick={() => {
        setBrowseQuestion(quiz.questions.length - 1);
      }} />
    </Pagination>


    {
      !!activeQuestion && <div key={`edit-question-${activeQuestion.id}`}>
        <EditQuizQuestion
          quiz={quiz}
          quizMeta={quizMeta}
          question={activeQuestion}
          questionIndex={browseQuestion}
          moveQuestionUp={moveQuestionUp}
          moveQuestionDown={moveQuestionDown}
          removeQuestion={removeQuestion}
          updateQuestion={updateQuestion}
        />
        <br />
        <hr />
        <br />
      </div>
    }

    <hr />
  </>;

}