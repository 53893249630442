
import {
  AtomicBlockUtils,
  EditorState,
} from 'draft-js';

export function insertAtomicBlock(editorState: EditorState, defaultContent: string, type: string) {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    type,
    'IMMUTABLE',
    {content: defaultContent, type: type},
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(
    editorState,
    {currentContent: contentStateWithEntity},
  );
  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
}