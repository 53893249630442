import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Form, FormControl, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { userAccessTokenState, userProfileState } from "../state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "../state/share";
import copy from 'clipboard-copy';
import { toast } from "react-toastify";
import { validateCountry, validateUsername } from "../validators/user";
import { getAPIPath } from "../core";
import { countryList } from "../data";


export const SetupProfile = () => {

  const navigate = useNavigate();

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, } = useAuth0();
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);

  const [isLoading, setIsLoading] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptMarketing, setAcceptMarketing] = useState(false);


  const [nickname, setNickname] = useState("");
  const [nameFirst, setNameFirst] = useState("");
  const [nameLast, setNameLast] = useState("");
  const [country, setCountry] = useState("");

  const [userProfile, setUserProfile] = useRecoilState(userProfileState);

  const validateForm = () => {
    return validateUsername(nickname) && nameFirst && nameLast && validateCountry(country) && acceptTerms;
  }

  const updateProfile = async () => {

    setIsLoading(true);
    const putRes = await fetch(getAPIPath() + `/api/user/profile`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify({
        nickname: nickname,
        nameFirst: nameFirst,
        nameLast: nameLast,
        country: country,
        acceptTerms: acceptTerms,
        acceptMarketing: acceptMarketing
      })
    });

    if (putRes.status !== 200) {
      setIsLoading(false);
      toast.error("Error updating profile");
      return;
    }

    // Update ok, fetch new user profile
    const fetchRes = await fetch(getAPIPath() + `/api/user/profile`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
      },
      method: 'GET'
    });

    if (fetchRes.status !== 200) {
      setIsLoading(false);
      toast.error("Error fetching profile");
      return;
    }

    const data = await fetchRes.json();
    setUserProfile(data);
    setIsLoading(false);
    toast.success("User profile updated");
    navigate(`/`);
  }

  return <>
    <Modal
      show={true}
      onHide={() => { }}
      backdrop="static"
      keyboard={false}
      size={'lg'}
    >
      <Modal.Header>
        <Modal.Title>Complete Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={9}>
            <strong>Username (Display Name)</strong>
            <InputGroup>

              <FormControl
                type="text"
                aria-label="default"
                aria-describedby="inputGroup-quiz-time"
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                  // setCodeStr(e.target.value);
                }}
              // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
              />
            </InputGroup>
            <span style={{ color: 'rgb(88,88,88)' }}>3-20 characters, alphanumerical</span>
          </Col>
          <Col md={3} className='d-none d-md-block'>
            {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
            <img src="/images/profile.svg" style={{ maxWidth: 80 }} />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <strong>First Name</strong>
            <InputGroup>

              <FormControl
                type="text"
                aria-label="default"
                aria-describedby="inputGroup-quiz-time"
                value={nameFirst}
                onChange={(e) => {
                  setNameFirst(e.target.value);
                  // setCodeStr(e.target.value);
                }}
              // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
              />
            </InputGroup>
          </Col>
          <Col sm={6}>
            <strong>Last Name</strong>
            <InputGroup>

              <FormControl
                type="text"
                aria-label="default"
                aria-describedby="inputGroup-quiz-time"
                value={nameLast}
                onChange={(e) => {
                  setNameLast(e.target.value);
                  // setCodeStr(e.target.value);
                }}
              // onChange={(e) => { setQuiz({ ...quiz, defaultTimeLimit: Number(e.target.value) }); }}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <strong>Country</strong>
            <InputGroup>

              <Form.Select aria-label="Default select example"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <option>Select country</option>
                {countryList.map((country, index) => {
                  return <option key={index} value={country.code}>{country.name}
                  </option>
                })}
              </Form.Select>
            </InputGroup>
          </Col>
          <Col sm={6}>

          </Col>
        </Row>
                
        <hr />
        <Row>
          <Col>
            <Form.Check value={acceptTerms ? 1 : 0} onChange={(e) => { 
              setAcceptTerms(e.target.checked); 
              if (e.target.checked) toast(`Let's goo 🚀🌑`);
            }} id={`profile-accept-terms`} label={<>I agree to the <a href="3" target="_blank">Terms of Service</a></>} />
            <Form.Check value={acceptMarketing ? 1 : 0} onChange={(e) => {  
              setAcceptMarketing(e.target.checked); 
              if (e.target.checked) toast.info(`Great stuff! We will send you updates - but won't spam you.`);
            }} id={`profile-accept-marketing`} label={<>I wish to receive newsletters and updates about the service.</>} />
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="lg" style={{ width: '100%' }} disabled={
          !validateForm() && !isLoading
        } onClick={() => {
          updateProfile();
        }}>
          {isLoading && <><Spinner animation="border" variant="primary" as="span" /></>}
          {!isLoading && <>Done</>}
        </Button>

      </Modal.Footer>
    </Modal>
  </>;
}