export enum EditMode {
  Create,
  Edit
}

export enum PublicBrowserType {
  Mine,
  Subscribed,
  Public
}

export enum QuestionType {
  MultipleChoiceQuestion="MultipleChoiceQuestion",
  TextQuestion="TextQuestion",
  FlashCardQuestion="FlashCardQuestion",
}

export interface ServerUserProfileSubscription {
  date_added: string
  date_current_period_start: string|null
  date_current_period_end: string|null
  name: string,
  local_id: number
}

export interface ServerUserProfile {
  user: {
    date_registered: Date
    email: string
    localid: number
    nickname: string
    verified: boolean
    profile_setup: boolean,
    name_first: string,
    name_last: string,
    country: string,
    sharecode: string,
    activated: boolean,
    flg_subscription_active: boolean
  },
  subscription: null | ServerUserProfileSubscription
}

// The Quiz object is the "mother" of the quiz content, and contains all the data inside the 'content' field.
// this is only stuff related to the questions etc... all other information is to be stored in the meta object
export interface Quiz { 
  title: string,
  description: string,
  defaultTimeLimit: number,
  overallTimeLimit: number,
  questions: Question[]
}

export interface QuizMeta {
  category_sub_id: number|null,
  category_parent_id: number|null,
  accessibility: QuizAccessibilityLevel
  control_flow: QuizControlFlowLevel
  results_display_mode: QuizResultsDisplayMode
}

export enum QuizAccessibilityLevel {
  Public = 0x0,
  Unlisted = 0x1,
  Private = 0x2,
}

export enum QuizControlFlowLevel {
  StrictChronological = 0x0,
  RelaxedFreeOrder = 0x1,
}

export enum QuizResultsDisplayMode {
  Immediately = 0x0,
  AtEnd = 0x1,
}

export interface ServerQuizLight {
  id: number,
  name: string,
  date_created: Date
  views: number,
  stars: number,
  public: boolean,
  creator: number,
  sharecode: string,
  meta_questions_count: number
  accessibility: QuizAccessibilityLevel
  control_flow: QuizControlFlowLevel
  category_sub_id: number,
  category_parent_id: number,
}

export interface ServerQuizFull extends ServerQuizLight {
 content: Quiz
}

export interface ServerAuthor {
  user_id: number,
  nickname: string,
}

export interface ServerSubscriber {
  user_id: number,
  nickname: string,
  sharecode: string
}

export interface ServerLeagueTableEntry {
  user_id: number,
  nickname: string,
  sharecode: string
  num_pct: number,
}

export interface ServerUserQuizResults {
  date_created: string,
  num_questions: number,
  num_correct: number,
  num_pct: number,
  name: string,
  sharecode: string,
}

export interface ServerQuizCategory {
  id: number
  name: string
  parent_category_id: number|null
}

export interface ServerUserLimits {
  privateQuizLimitMet: boolean
  privateQuizesCount: number
}

export interface ServerInviteCode {
  user_id: number,
  sharecode: string,
  redeemed: string,
  invited_user_id: string,
  invited_user_name: string,
  invited_user_sharecode: string,
  date_created: string,
  date_redeemed: string,
}

export interface ServerCollection {
  id: number,
  sharecode: string,
  name: string,
  date_created: string,
}

// --- QUESTIONS
// 3 types of Question:
// 1. Multiple choice
export interface MultipleChoiceQuestion {
  type: "MultipleChoiceQuestion"
  order: number
  id: string
  timeLimit: number|null
  questionBody: string
  katexQuestionBody: string
  answers: MultipleChoiceAnswer[]
  correctAnswerIds: string[] 
  shuffleAnswers: boolean
  createdBy: string
  solutionExplanation: string
  solutionExplanationKatex: string
  allMustBeCorrect: boolean
  allowMultipleAnswers: boolean
  allowMultipleAnswersMax: number|null
}

export interface MultipleChoiceAnswer {
  id: string
  description: string
  descriptionKatex: string
}

// 2. Text input with required words to be present
export interface TextQuestion {
  type: "TextQuestion"
  order: number
  id: string
  timeLimit: number|null
  questionBody: string
  katexQuestionBody: string
  answer: string
  createdBy: string
  solutionExplanation: string
  solutionExplanationKatex: string
}

// 3. Flashcard type answer, self proclaimed correct/not correct
export interface FlashCardQuestion {
  type: "FlashCardQuestion"
  order: number
  id: string
  timeLimit: number|null
  questionBody: string
  katexQuestionBody: string
  solutionExplanation: string
  solutionExplanationKatex: string
  answer: boolean
  createdBy: string
}

// --- ANSWERS
export interface UserAnswerMultipleChoice {
  questionId: string
  answerId: string[]
  timeout: boolean
}

export interface UserAnswerText {
  questionId: string
  timeout: boolean
  answer: string
}

export interface UserAnswerFlashCard {
  questionId: string
  timeout: boolean
  answer: boolean
}

// Question union type & typeguards 

export type Question = MultipleChoiceQuestion | TextQuestion | FlashCardQuestion;

export function isMultipleChoiceQuestion(q: Question): q is MultipleChoiceQuestion {
  return (q as MultipleChoiceQuestion).type === "MultipleChoiceQuestion";
}

export function isTextQuestion(q: Question): q is TextQuestion {
  return (q as TextQuestion).type === "TextQuestion";
}

export function isFlashCardQuestion(q: Question): q is FlashCardQuestion {
  return (q as FlashCardQuestion).type === "FlashCardQuestion";
}


export type UserAnswer = UserAnswerMultipleChoice | UserAnswerText | UserAnswerFlashCard;

// --- Results 
export interface UserQuizResult {
  numCorrect: number,
  numAns: number,
  numAnsPct: number
}