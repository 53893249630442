import { useEffect, useState } from "react";
import { Button, ButtonGroup, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getAPIPath } from "../core";
import { PublicBrowserType, QuizAccessibilityLevel, ServerQuizLight } from "../quiz-types";
import { userAccessTokenState } from "../state/mainstate";
import { activeShareQuizState, activeShareWindowState } from "../state/share";

interface Props {
  quizes: ServerQuizLight[]
  loadQuizes(): void,
  type: PublicBrowserType
};

export const PublicBrowser = (props: Props) => {

  const navigate = useNavigate();

  const userAccessToken = useRecoilValue(userAccessTokenState);


  const setShareCodeOpen = useSetRecoilState(activeShareWindowState);
  const setShareQuiz = useSetRecoilState(activeShareQuizState);

  const unsubscribeFromQuiz = async (quiz: ServerQuizLight) => {
    const response = await fetch(getAPIPath() + `/api/quiz/quiz/me/subscriptions/${quiz.sharecode}`, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });
    if (response.ok) {
      props.quizes.splice(props.quizes.indexOf(quiz), 1);
    }
    toast.info("Unsubscribed from quiz");
    props.loadQuizes();
  }

  return <>
    <Table striped hover size="sm">
      <thead>
        <tr className="d-flex">
          <th className="col-xl-6 col-sm-5 col-7">Name</th>
          <th className="col-xl-1 col-sm-1 d-none d-sm-table-cell">Views</th>
          <th className="col-xl-1 col-sm-1 d-none d-sm-table-cell">Stars</th>
          <th className="col-xl-1 col-sm-1 d-none d-sm-table-cell">Questions</th>
          <th className="col-xl-3 col-sm-4 col-5"></th>
        </tr>
      </thead>
      <tbody>
        {props.quizes.map((quiz, index) => {
          return <tr key={`quiz-${quiz.id}`} className="d-flex">
            <td className="col-xl-6 col-sm-5 col-7">
              <Link to={`/quiz/${quiz.sharecode}`}>{quiz.name}</Link>
              {props.type === PublicBrowserType.Mine && quiz.accessibility === QuizAccessibilityLevel.Public && <span style={{ marginLeft: 10 }}>
                <OverlayTrigger overlay={<Tooltip>Public</Tooltip>}>
                  <i className="bi bi-people-fill" style={{ color: '#757575' }}></i>
                </OverlayTrigger>
              </span>}
            </td>
            <td className="col-xl-1 col-sm-1 d-none d-sm-table-cell">{quiz.views}</td>
            <td className="col-xl-1 col-sm-1 d-none d-sm-table-cell">{quiz.stars}</td>
            <td className="col-xl-1 col-sm-1 d-none d-sm-table-cell">{quiz.meta_questions_count}</td>
            <td className="col-xl-3 col-sm-4 col-5">
              <ButtonGroup aria-label="Basic example">
                <Button onClick={() => {
                  navigate(`/quiz/${quiz.sharecode}/take`);
                }}><i className="bi bi-play"></i>
                  <span className="d-none d-lg-inline-block"><strong>Take</strong></span>
                </Button>

                {props.type === PublicBrowserType.Mine && <Button variant="dark" onClick={() => {
                  navigate(`/quiz/${quiz.sharecode}/edit`);
                }}><i className="bi bi-pencil"></i>
                  <span className="d-none d-lg-inline-block"><strong>Edit</strong></span>
                </Button>}

                <Button variant="dark" onClick={() => {
                  setShareCodeOpen(true);
                  setShareQuiz(quiz);
                }}><i className="bi bi-share"></i>
                  <span className="d-none d-lg-inline-block">Share</span>
                </Button>

              </ButtonGroup>
              {props.type === PublicBrowserType.Subscribed && <>
              <ButtonGroup aria-label="Basic example">
                <Button variant="dark" onClick={() => {
                  // confirm that user wants to unsubscribe
                  if (!window.confirm("Are you sure you want to unsubscribe from this quiz?")) return;
                  unsubscribeFromQuiz(quiz);
                }}><i className="bi bi-trash"></i></Button>
              </ButtonGroup>
              </>}
            </td>
          </tr>
        })}
      </tbody>
    </Table>
  </>;
};