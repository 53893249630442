import { Button, Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import React from "react";
import { MultipleChoiceAnswer, MultipleChoiceQuestion, Question, Quiz } from "../quiz-types";
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import hash from 'object-hash';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";

import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'

interface Props {
  question: MultipleChoiceQuestion
  questionIndex: number
  updateQuestion(question: Question): void
}

export const EditMultipleChoiceQuestion = (props: Props) => {

  const { question, questionIndex, updateQuestion } = props;
  const questionObj = question as MultipleChoiceQuestion;

  const addAnswer = () => {
    const answersCop = JSON.parse(JSON.stringify(questionObj.answers)) as MultipleChoiceAnswer[];
    const newAnswer: MultipleChoiceAnswer = {
      id: uuidv4(),
      description: '',
      descriptionKatex: ''
    };
    answersCop.push(newAnswer);
    updateQuestion({ ...question, answers: answersCop })
  };
  const updateMaxAnswers = (maxNumbers: string) => {
    const maxNumbersVal = (maxNumbers.trim() === '' || maxNumbers === null) ? null : Number(maxNumbers);
    updateQuestion({ ...question, allowMultipleAnswersMax: maxNumbersVal });

  }

  return <>
    <h4>Answers</h4>
    <Form.Group controlId={`${questionObj.id}-answers-correct-cb`}>
      <Form.Check type="checkbox" label="Require all answers to be correct"
        checked={questionObj.allMustBeCorrect}
        onChange={(e) => {
          const newState = e.target.checked;
          const newQuestion = { ...question, allMustBeCorrect: e.target.checked };
          if (!newState) newQuestion.allowMultipleAnswers = false; // if allMustBeCorrect=false, allowMultipleAnswers=true doesnt make sense.
          updateQuestion(newQuestion);
        }}
      />
    </Form.Group>
    <Form.Group controlId={`${questionObj.id}-allow-multiple-answers`}>
      <Form.Check type="checkbox" label="Allow multiple answers"
        checked={questionObj.allowMultipleAnswers}
        onChange={(e) => { updateQuestion({ ...question, allowMultipleAnswers: e.target.checked }) }}
      />
    </Form.Group>
    <Form.Group controlId={`${questionObj.id}-shuffle-answers`}>
      <Form.Check type="checkbox" label="Shuffle Answers"
        checked={questionObj.shuffleAnswers}
        onChange={(e) => {
          const newState = e.target.checked;
          const newQuestion = { ...question, shuffleAnswers: e.target.checked };
          updateQuestion(newQuestion);
        }}
      />
    </Form.Group>
    {questionObj.allowMultipleAnswers && <Form.Group controlId={`${questionObj.id}-allow-multiple-answers-num`}>
      <InputGroup className="mb-3">
        <InputGroup>
          <InputGroup.Text>Max answers (blank for unlimited)</InputGroup.Text>
        </InputGroup>
        <FormControl
          aria-label="With textarea"
          value={questionObj.allowMultipleAnswersMax === null ? '' : questionObj.allowMultipleAnswersMax.toString()}
          onChange={(e) => { updateMaxAnswers(e.target.value); }}
        />
      </InputGroup>
    </Form.Group>}


    {questionObj.answers.map((answer, answerIndex) => {

      const deleteAnswer = (targetAnswer: MultipleChoiceAnswer) => {
        const answersCop = JSON.parse(JSON.stringify(questionObj.answers)) as MultipleChoiceAnswer[];
        const correctAnswersCop = JSON.parse(JSON.stringify(questionObj.correctAnswerIds)) as string[];

        const answerIndex = answersCop.findIndex((el) => { return el.id === targetAnswer.id; })
        if (answerIndex === -1) return;

        // If answer was correct answer, remove from correct answers
        if (correctAnswersCop.includes(targetAnswer.id)) {
          const correctAnswerIndex = correctAnswersCop.findIndex((el) => { return el === targetAnswer.id });
          correctAnswersCop.splice(correctAnswerIndex, 1);
        }

        // Remove answers from array
        answersCop.splice(answerIndex, 1);
        updateQuestion({ ...question, correctAnswerIds: correctAnswersCop, answers: answersCop })
      }

      const makeAnswerCorrect = (targetAnswer: MultipleChoiceAnswer) => {
        const correctAnswersCop = JSON.parse(JSON.stringify(questionObj.correctAnswerIds)) as string[];
        if (correctAnswersCop.includes(targetAnswer.id)) return; // already in there 
        correctAnswersCop.push(targetAnswer.id);
        updateQuestion({ ...question, correctAnswerIds: correctAnswersCop })
      }

      const makeAnswerWrong = (targetAnswer: MultipleChoiceAnswer) => {
        const correctAnswersCop = JSON.parse(JSON.stringify(questionObj.correctAnswerIds)) as string[];
        if (!correctAnswersCop.includes(targetAnswer.id)) return; // already in there 
        const correctAnswerIndex = correctAnswersCop.findIndex((el) => { return el === targetAnswer.id });
        correctAnswersCop.splice(correctAnswerIndex, 1);
        updateQuestion({ ...question, correctAnswerIds: correctAnswersCop })
      }

      const updateAnswerDescription = (targetAnswer: MultipleChoiceAnswer, description: string, descriptionKatex: string) => {
        const answersCop = JSON.parse(JSON.stringify(questionObj.answers)) as MultipleChoiceAnswer[];
        const answerIndex = answersCop.findIndex((el) => { return el.id === targetAnswer.id; })
        if (answerIndex === -1) return;
        const newAnswer = {
          ...targetAnswer,
          description,
          descriptionKatex
        }
        answersCop[answerIndex] = newAnswer;
        updateQuestion({ ...question, answers: answersCop });
      }

      return <Row key={`edit-question-answer-${answer.id}`}>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Answer - {answerIndex + 1}</Card.Title>
              <Row>
                <Col>
                  <Form.Group controlId={`${answer.id}-ans-is-correct`}>
                    <Form.Check type="checkbox" label="Answer is correct"
                      checked={questionObj.correctAnswerIds.includes(answer.id)}
                      onChange={(e) => { if (e.target.checked) { makeAnswerCorrect(answer); } else { makeAnswerWrong(answer); } }}
                    />
                  </Form.Group>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    variant="danger"
                    onClick={() => { deleteAnswer(answer); }}
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </Col>
              </Row>


              <Row>
                <Col></Col>
                <Col>Preview</Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Answer description</InputGroup.Text>
                    </InputGroup>
                    <FormControl
                      as="textarea"
                      aria-label="With textarea"
                      value={answer.description}
                      onChange={(e) => { updateAnswerDescription(answer, e.target.value, answer.descriptionKatex); }}
                    />
                  </InputGroup>
                </Col>
                <Col><ReactMarkdown className='app-markdown'
                  // @ts-ignore
                  remarkPlugins={[remarkGfm, remarkMath]}
                  rehypePlugins={[rehypeKatex]}
                >{answer.description}</ReactMarkdown></Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup>
                    <InputGroup>
                      <InputGroup.Text>Answer description (Katex)</InputGroup.Text>
                    </InputGroup>
                    <FormControl
                      as="textarea"
                      aria-label="With textarea"
                      value={answer.descriptionKatex}
                      onChange={(e) => { updateAnswerDescription(answer, answer.description, e.target.value); }}
                    />
                  </InputGroup>
                </Col>
                <Col>{answer.descriptionKatex !== '' && <TeX math={answer.descriptionKatex} block />}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    })}

    <Button variant="dark" onClick={() => { addAnswer(); }}><i className="bi bi-plus"></i> Add answer</Button>
  </>;
}