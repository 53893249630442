import { useEffect } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { getAPIPath } from "./core";
import { EditQuiz } from "./EditQuiz/EditQuiz";
import { EditMode, Quiz, QuizAccessibilityLevel, QuizControlFlowLevel, QuizMeta, QuizResultsDisplayMode } from "./quiz-types";
import { activeQuiz, activeQuizMeta, editQuizModeState, userAccessTokenState } from "./state/mainstate";

export const CreateQuiz = () => {

  const navigate = useNavigate();

  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);
  const [editMode, setEditMode] = useRecoilState(editQuizModeState);
  const userAccessToken = useRecoilValue(userAccessTokenState);


  const createQuiz = async () => {
    
    const {title} = (quiz as Quiz);
    const {category_parent_id, category_sub_id, accessibility, control_flow, results_display_mode} = (quizMeta as QuizMeta);

    const response = await fetch(getAPIPath() + "/api/quiz/quiz", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userAccessToken}`
      },
      body: JSON.stringify({
        name: title,
        category_sub_id,
        category_parent_id,
        accessibility,
        control_flow,
        results_display_mode,
        quiz
      })
    });

    // Handle errors with code 400 (invalid input)
    if (response.status === 400) {
      const data = await response.json();
      if (data.code !== undefined && data.code === 'private_quiz_limit_met') {
        toast.error("You have reached the limit of private quizes. Please upgrade your account to create more or make it public.");
        return;
      } else {
        toast.error("There was an error creating the quiz. Please try again later.");
        return;
      }
    }
    // Fallback for everything else (non-200!)
    else if (response.status !== 200) { 
      toast.error("There was an error creating the quiz. Please try again later.");
      return;
    }

    // Success!
    const data = await response.json();

    const sharecode = data.sharecode;

    setEditMode(null);
    toast.success("Quiz created");
    navigate(`/quiz/${sharecode}`)

  }

  useEffect(() => {

    const newQuiz: Quiz = {
      title: '',
      description: '',
      defaultTimeLimit: 0,
      overallTimeLimit: 0, 
      questions: []
    };
    const newMeta: QuizMeta = {
      category_parent_id: null,
      category_sub_id: null,
      accessibility: QuizAccessibilityLevel.Public,
      control_flow: QuizControlFlowLevel.StrictChronological,
      results_display_mode: QuizResultsDisplayMode.Immediately
    };
    setQuiz(newQuiz);
    setQuizMeta(newMeta);
    setEditMode(EditMode.Create);
  }, []);

  return <>
  <EditQuiz createQuiz={createQuiz} />
  </>;
};