import { useAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { CreateQuiz } from "./CreateQuiz";
import { ViewQuizDetails } from "./ViewQuizDetails";
import { EditQuizMother } from "./EditQuizMother";
import { MainPage } from "./MainPage";
import { MainTheme } from "./MainTheme";
import { TakeQuizActive } from "./TakeQuiz/TakeQuizActive";
import { TakeQuizBrowser } from "./TakeQuiz/TakeQuizBrowser";
import { MyLearning } from "./User/MyLearning";
import { UserProfile } from "./User/UserProfile";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { userAccessTokenState, userProfileState } from "./state/mainstate";
import { toast } from "react-toastify";
import { getAPIPath } from "./core";
import { RedirectSubscription } from "./User/RedirectSubscription";
import {  RichEditor } from "./RichEditor/RichEditor";
import { CollectionBrowser } from "./Collection/CollectionBrowser";
import { CreateCollection } from "./Collection/CreateCollection";
import { ViewCollectionDetails } from "./Collection/ViewCollectionDetails";


export const MainRouter = () => {

  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);


  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();


  useEffect(() => {


    const loadUserProfile = async () => {
      const resp = await fetch(getAPIPath() + '/api/user/profile', {
        headers: {
          'Authorization': `Bearer ${userAccessToken}`
        }
      });
  
      if (resp.status !== 200) {
        toast.error("Failed to load user profile");
        return;
      }
  
      const userProfile = await resp.json();
      if (userProfile.user === undefined) {
        toast.error("Failed to load user profile");
        return;
      }
  
      setUserProfile(userProfile);
  
    }
  
      // Load data on authenticated and user access token is not empty
      if (isAuthenticated && userAccessToken !== '') {
        loadUserProfile();
      }
  
  
    }, [isAuthenticated, userAccessToken]);
  

  if (isLoading) {
    // 333F51
    return <div style={{
      width: '100vw', height: '100vh', position: 'absolute', backgroundColor: '#FFF', textAlign: 'center', display: 'grid',
      placeItems: 'center'
    }}>

      <img className='eduerity-loader' src="/images/brain.svg" style={{ alignSelf: 'center' }} />

    </div>;
  }



  return <Router><Routes>
    
    <Route path="/">
      
      {/* Quiz */}
      <Route path="/join" element={<MainTheme><TakeQuizBrowser joinByCode={true} /></MainTheme>} />
      <Route path="/join/:code" element={<MainTheme><TakeQuizBrowser joinByCode={true} /></MainTheme>} />

      <Route path="/share/:code" element={<MainPage joinByCode={true} />} />
      <Route path="/quiz" element={<MainTheme><TakeQuizBrowser /></MainTheme>} />
      
      
      <Route path="/quiz/:code" element={<MainTheme><ViewQuizDetails /></MainTheme>}/>
      <Route path="/quiz/:code/take" element={<MainTheme><TakeQuizActive /></MainTheme>} />
      <Route path="/quiz/:code/edit" element=
      {<MainTheme><EditQuizMother /></MainTheme>} />
      <Route path="/create" element=
      {<MainTheme><CreateQuiz /></MainTheme>} />

      {/* Collections */}
      <Route path="/collection" element={<MainTheme><CollectionBrowser /></MainTheme>} />
      <Route path="/collection/create" element={<MainTheme><CreateCollection /></MainTheme>} />
      <Route path="/collection/:id" element={<MainTheme><ViewCollectionDetails /></MainTheme>} />


      {/* Users */}
      <Route path="/learning" element={<MainTheme><MyLearning /></MainTheme>}/>

      <Route path="/subscription/:action" element={<MainTheme><RedirectSubscription /></MainTheme>}/>
      <Route path="/profile/:code" element={<MainTheme><UserProfile /></MainTheme>}/>
      <Route path="/profile/:code/upgrade" element={<MainTheme><UserProfile upgrade={true} /></MainTheme>}/>

      <Route path="/editor" element={<MainTheme><RichEditor /></MainTheme>}/>

      <Route index element={<MainTheme><TakeQuizBrowser /></MainTheme>} />
      {/* <Route index element={<MainPage />} /> */}
      {/* <Route path="quizes" element={<Quizes />}>
        <Route path=":quizId" element={<ViewQuiz />} />
        <Route path=":quizId/take" element={<TakeQuiz />} />
        <Route path=":quizId/edit" element={<EditQuiz />} />
        <Route path="new" element={<NewQuiz />} />
        <Route index element={<QuizBrowser />} />
      </Route> */}
    </Route>
  </Routes></Router>;
}