import { ContentState, EditorState } from "draft-js";
import { ImageBlock } from "./blocks/ImageBlock";
import { TeXBlock } from "./blocks/TeXBlock";

interface Props {
  block: any
  blockProps: any
  contentState: ContentState
  onStartEdit(blockKey: string): void
  onFinishEdit(blockKey: string, newContentState: EditorState): void
  onRemove(blockKey: string): void
  editorRef: any
}

export const AtomicRenderer = (props: Props) => {
  const { foo } = props.blockProps;
  const { block, contentState } = props;

  const entity = contentState.getEntity(block.getEntityAt(0));
  const data = entity.getData();
  const type = entity.getType();

  const {onStartEdit, onFinishEdit, onRemove} = props;

  console.log('AtomicRenderer type', type);

  switch (type) {
    /*You know what you should do here :D*/
    case 'IMAGE':
      return <ImageBlock 
        block={block}
        blockProps={props.blockProps}
        contentState={contentState}
        onStartEdit={onStartEdit}
        onFinishEdit={onFinishEdit}
        onRemove={onRemove}
        editorRef={props.editorRef}
      />;
    case 'TEX':
    default:
      return <TeXBlock
        block={block}
        blockProps={props.blockProps}
        contentState={contentState}
        onStartEdit={onStartEdit}
        onFinishEdit={onFinishEdit}
        onRemove={onRemove}
        editorRef={props.editorRef}
      />
  }

}