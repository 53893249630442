import { Editor, EditorState, RichUtils } from 'draft-js';
// @ts-ignore
import TeX from '@matejmazur/react-katex';
import { useEffect, useState } from 'react';
import katex from 'katex';

interface Props {
  onStartEdit(blockKey: string): void
  onFinishEdit(blockKey: string, newContentState: EditorState): void
  onRemove(blockKey: string): void
  contentState: any
  block: any
  blockProps: any
  editorRef: any
}

export const TeXBlock = (props: Props) => {

  const [editMode, setEditMode] = useState(false);
  const [texValue, setTexValue] = useState<string|undefined>('');
  const [invalidTeX, setInvalidTeX] = useState(false);
  const [newContentStateSave, setNewContentStateSave] = useState<any>(null);
  
  const [doSave, setDoSave] = useState(false);

  const getValue = () => {
    return props.contentState.getEntity(props.block.getEntityAt(0)).getData()['content'];
  }

  const finishEdit = (newContentState: any) => {
    props.blockProps.onFinishEdit(
      props.block.getKey(),
      newContentState,
    );
  }


  const save = () => {
    const entityKey = props.block.getEntityAt(0);
    const newContentState = props.contentState.mergeEntityData(entityKey, { content: texValue });
    setEditMode(false);
    setInvalidTeX(false);
    setTexValue('');
    setNewContentStateSave(newContentState);
    setDoSave(true);
  }

  useEffect(() => {
    console.log('doSave er nå', doSave);
    if (doSave) {
      console.log('finish edit!!!');
      finishEdit(newContentStateSave); 
      console.log(props.editorRef);
      setDoSave(false);
      // (props.editorRef.current as any).focus();
    }

  }, [doSave]);

  // // Act on the save information
  // useEffect(() => {

  //   if (newContentStateSave !== null && invalidTeX === false && editMode === false && texValue === undefined) {
  //     // props.finished
  //     finishEdit(newContentStateSave);
  //   }

  // }, [invalidTeX, editMode, texValue, newContentStateSave])

  // useEffect(() => {
  //   if (editMode === true && texValue !== undefined) {
  //     startEdit();
  //   }
  // }, [editMode, texValue])

  const remove = () => {
    props.blockProps.onRemove(props.block.getKey());
  }

  const startEdit = () => {
    props.blockProps.onStartEdit(props.block.getKey());
  }

  const onClick = () => {

    if (editMode) return;
    setEditMode(true);
    setTexValue(getValue());
    startEdit();

  };

  const onValueChange = (evt: any) => {
    var value = evt.target.value;
      var invalid = false;
      try {
        katex.renderToString(value);
      } catch (e) {
        invalid = true;
      } finally {
        setInvalidTeX(invalid);
        setTexValue(value);
      }
  }

  var texContent = null;
  if (editMode) {
    if (invalidTeX) {
      texContent = '';
    } else {
      texContent = texValue;
    }
  } else {
    texContent = getValue();
  }

  var className = 'TeXEditor-tex';
  if (editMode) {
    className += ' TeXEditor-activeTeX';
  }

  var editPanel = null;
  if (editMode) {
    var buttonClass = 'TeXEditor-saveButton';
    if (invalidTeX) {
      buttonClass += ' TeXEditor-invalidButton';
    }

    // TODO: Reconsider the positioning / styling of this component
    editPanel =
      <div className="TeXEditor-panel" style={{position: 'absolute', bottom: 0, left: 0, marginBottom: -100, zIndex: 5}}>
        <textarea
          className="TeXEditor-texValue"
          onChange={onValueChange}
          // ref="textarea"
          value={texValue}
        />
        <div className="TeXEditor-buttons">
          <button
            className={buttonClass}
            disabled={invalidTeX}
            onClick={save}>
            {invalidTeX ? 'Invalid TeX' : 'Done'}
          </button>
          <button className="TeXEditor-removeButton" onClick={remove}>
            Remove
          </button>
        </div>
      </div>;
  }

  return (
    <div className={className}>
      {/* <KatexOutput content={texContent} onClick={this._onClick} /> */}
      <TeX math={texContent} onClick={onClick}  />
      {editPanel}
    </div>
  );
}


  // <TeX math={question.katexQuestionBody} block />
  // return <div> <TeX math={question.katexQuestionBody} onClick={} /> </div>
