import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Modal, CloseButton, Row, Col, InputGroup, FormControl, Button, Spinner, ListGroup, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { getAPIPath } from "../core";
import { ServerCollection } from "../quiz-types";
import { activeQuiz, activeQuizMeta, userAccessTokenState, userProfileState } from "../state/mainstate";

interface Props {
  show: boolean
  onHide: () => void
  quizSharecode: string | undefined
}

export const AddToCollection = (props: Props) => {


  const [quiz, setQuiz] = useRecoilState(activeQuiz);
  const [quizMeta, setQuizMeta] = useRecoilState(activeQuizMeta);

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);
  const [myCollections, setMyCollections] = useState<ServerCollection[]>([]);
  const [inCollections, setInCollections] = useState<ServerCollection[]>([]);
  const [selectCollection, setSelectCollection] = useState<ServerCollection | undefined>(undefined);

  const [loadingCollections, setLoadingCollections] = useState(false);
  const loadMyCollections = async () => {
    setLoadingCollections(true);

    // Fetch my collections
    const resp = await fetch(getAPIPath() + '/api/collection/me', {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (resp.status !== 200) {
      toast.error("Failed to load collections");
      setLoadingCollections(false);
      return;
    }

    // Fetch collections the quiz is in
    const respCollections = await fetch(getAPIPath() + `/api/collection/quiz/${props.quizSharecode}`, {
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (respCollections.status !== 200) {
      toast.error("Failed to load collections");
      setLoadingCollections(false);
      return;
    }

    const collections = await resp.json();
    setMyCollections(collections.collections);

    // load incollections
    const collectionsIn = await respCollections.json();
    setInCollections(collectionsIn.collections);

    setLoadingCollections(false);
  }

  const addToCollection = async () => {

    if (!selectCollection) {
      toast.error("Please select a collection");
      return;
    }

    const resp = await fetch(getAPIPath() + `/api/collection/${selectCollection.sharecode}/quiz/${props.quizSharecode}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userAccessToken}`
      }
    });

    if (resp.status !== 200) {
      toast.error("Failed to add quiz to collection");
      return;
    }

    toast.success("Quiz added to collection");
    // Append the newly added collection to the list of In Collections
    setInCollections([...inCollections, selectCollection]);
    // Reset collection selector 
    setSelectCollection(undefined);

    // Close the modal
    props.onHide();


  }

  const createCollection = async () => {


    const name = prompt("Enter a name for the collection");
    if (!name) {
      toast.info("Collection creation cancelled");
      return;
    }

    const loadingToast = toast.info("Creating collection...", {
      autoClose: false
    });


    // Create a new collection with the specificied name
    const resp = await fetch(getAPIPath() + '/api/collection', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${userAccessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name
      })
    });

    if (resp.status !== 200) {
      toast.dismiss(loadingToast);
      const errRespData = await resp.json();
      toast.error(errRespData.status === false ? errRespData.message : "Failed to create collection");
      return;
    }

    const collection = await resp.json();

    // Trigger re-load of collections
    loadMyCollections();

    // Inform of successfull creation
    toast.dismiss(loadingToast);
    toast.success("Collection created");

  }

  useEffect(() => {
    loadMyCollections();
  }, []);


  if (quiz === undefined || quiz === null || quizMeta === undefined || quizMeta === null) {
    return <></>;
  }

  return <Modal
    show={props.show}
    onHide={() => { props.onHide(); }}
    onEscapeKeyDown={() => { props.onHide(); }}
    keyboard={false}
    size={'lg'}
  >
    <Modal.Header>
      <Modal.Title>Add to collection - {quiz.title}</Modal.Title>
      <CloseButton onClick={() => {
        props.onHide();
      }} />
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col md={9}>

          {loadingCollections && <div style={{ textAlign: 'center', width: '100%' }}>
            <Spinner animation="border" variant="primary" />
          </div>}
          {!loadingCollections && <>
            <ListGroup>
              {myCollections.map((el) => {

                const isInCollection = inCollections.find((el2) => el2.id === el.id) !== undefined;
                const isSelected = selectCollection !== undefined && selectCollection.id === el.id;

                return <ListGroup.Item
                  key={el.id}
                  disabled={isInCollection}
                  onClick={(e) => {
                    if (isInCollection) {
                      return;
                    }
                    if (isSelected) {
                      setSelectCollection(undefined);
                      return;
                    }
                    setSelectCollection(el);
                  }}
                  style={{
                    cursor: isInCollection ? 'auto' : 'pointer',
                    userSelect: 'none',
                  }}
                  active={isSelected}
                >
                  <Row>
                    <Col xs={10}>{el.name}</Col>
                    <Col xs={2} style={{ textAlign: 'right' }}>{isInCollection && <><i className="bi bi-check" style={{ color: 'darkgreen' }}></i></>}</Col>
                  </Row>
                </ListGroup.Item>
              })}
            </ListGroup>
            <br />

            <ButtonGroup>
              <Button
                variant="primary"
                onClick={addToCollection}
                disabled={selectCollection === undefined}
              >Add to collection</Button>
              <Button
                variant="secondary"
                onClick={createCollection}
              >New Collection</Button>
            </ButtonGroup>


          </>
          }

        </Col>
        <Col md={3}>
          {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
          <img src="/images/box.svg" style={{ maxWidth: 80 }} />
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
        </Col>
      </Row>

    </Modal.Body>
    <Modal.Footer>
      {/* <Button variant="primary" size="lg" style={{ width: '100%' }} onClick={() => {
          // setShareCodeOpen(false);
          // setShareQuiz(null);
        }}>Done</Button> */}

    </Modal.Footer>
  </Modal>;
}