import { ContentBlock, Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { TeXBlock } from './blocks/TeXBlock';
import { Map } from 'immutable';
import { insertTeXBlock } from './modifiers/insertTeXBlock';
import { AtomicRenderer } from './AtomicRenderer';
import {stateToHTML} from 'draft-js-export-html';
import { removeAtomicBlock } from './modifiers/removeAtomicBlock';
import { insertAtomicBlock } from './modifiers/insertAtomicBlock';

export const RichEditor = () => {
  const inputEl = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [liveAtomicEdits, setLiveAtomicEdits] = useState(Map());

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const removeTeX = (blockKey: any) => {
    setLiveAtomicEdits(liveAtomicEdits.remove(blockKey));
    setEditorState(removeAtomicBlock(editorState, blockKey));
  }

  const insertTeX = () => {
    setLiveAtomicEdits(Map());
    setEditorState(insertTeXBlock(editorState));
  }

  const insertImage = () => {
    setLiveAtomicEdits(Map());
    setEditorState(insertAtomicBlock(editorState, 'https://picsum.photos/200/300', 'IMAGE'));
  }

  const focus = (sender: null | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (inputEl.current === null) return;
    (inputEl.current as any).focus();

  }



  const blockRenderer = (contentBlock: ContentBlock) => {



    if (contentBlock.getType() === 'atomic') {
      return {
        component: AtomicRenderer,
        editable: false,
        props: {
          onStartEdit: (blockKey: any) => {
            setLiveAtomicEdits(liveAtomicEdits.set(blockKey, true));
          },
          onFinishEdit: (blockKey: any, newContentState: any) => {
            setLiveAtomicEdits(liveAtomicEdits.remove(blockKey));
            const preState = EditorState.createWithContent(newContentState);
            setEditorState(EditorState.forceSelection(preState, editorState.getSelection()));
          },
          onRemove: (blockKey: any) => removeTeX(blockKey),
          editorRef: inputEl
        }
      };
      
    }
    return null;

  };

  return <div>

    <h1>Eduerity Rich Editor</h1>
    <hr />


    <div className="TexEditor-container">
      <div className="TeXEditor-root">
        <div className="TeXEditor-editor" onClick={(e) => {
          focus(e);
        }}>
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            handleKeyCommand={handleKeyCommand}
            blockRendererFn={blockRenderer}
            placeholder='Start a document...'
            readOnly={liveAtomicEdits.count() > 0}
            ref={inputEl}
            spellCheck={true}
          />
        </div>
      </div>
      <Button size='sm' variant="deault" onClick={() => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
      }}>Bold</Button>

      <Button size='sm' variant="deault" onClick={() => {
        insertTeX();
      }}>
        Insert TeX
      </Button>

      <Button size='sm' variant="deault" onClick={() => {
        insertImage();
      }}>
        Insert Image
      </Button>

      <Button size='sm' variant="deault" onClick={() => {

        let options = {
          blockRenderers: {
            atomic: (block: ContentBlock) => {
              // let data = block.getData();

              const entity = editorState.getCurrentContent().getEntity(block.getEntityAt(0));
              const data = entity.getData();
              const type = entity.getType();

           
                
              if (type === 'TEX') {

                console.log('data');
                console.log(data);

                // return '<div>' + escape(block.getText()) + '</div>';
                return `<figure>${data.content}</figure>`
              } else if (type === 'IMAGE') {
                return `<img src="${data.content}" />`
              }
            },
          },
        };

        console.log(stateToHTML(editorState.getCurrentContent(), options as any));
      }}>
        Export to HTML
      </Button>

    </div>

    <hr />

    
  </div>;
}