import { isMultipleChoiceQuestion, isTextQuestion, MultipleChoiceAnswer, MultipleChoiceQuestion, TextQuestion, Question, Quiz, UserAnswer, UserAnswerMultipleChoice, UserAnswerText, UserQuizResult, isFlashCardQuestion, UserAnswerFlashCard } from "../quiz-types";
import { validateTextAnswer } from "../validators/textanswer";

export const calculateUserQuizResult = (quiz: Quiz, userAnswers: UserAnswer[]): UserQuizResult => {
  
  // Calculate the stats 
  let numCorrect = 0;
  const numAns = userAnswers.length;

  // Loop through all questions
  for (let question of quiz.questions) {

    let questionIsCorrect = undefined;
    const userAnswer = userAnswers.find((el) => { return el.questionId === question.id; })

    if (isMultipleChoiceQuestion(question)) {


      let allMustBeCorrectOK = true;
      let someMustBeCorrectOK = false;

      for (let correctAnswer of question.correctAnswerIds) {

        const userHasAnsweredCorrectly = (userAnswer as UserAnswerMultipleChoice).answerId.includes(correctAnswer);

        // IF all must be correct, check if wrong
        if (question.allMustBeCorrect && !userHasAnsweredCorrectly) {
          allMustBeCorrectOK = false;
          break;
        } else if (!question.allMustBeCorrect && userHasAnsweredCorrectly) {
          someMustBeCorrectOK = true;
          break;
        }
      }

      if (question.allMustBeCorrect) {
        questionIsCorrect = allMustBeCorrectOK;
      } else if (!question.allMustBeCorrect) {
        questionIsCorrect = someMustBeCorrectOK;
      }
    } else if (isTextQuestion(question)) {
      console.log('questionIsCorrect', question);
      questionIsCorrect = validateTextAnswer(question.answer, (userAnswer as UserAnswerText).answer);
    } else if (isFlashCardQuestion(question)) {
      questionIsCorrect = (userAnswer as UserAnswerFlashCard).answer;
    }

    numCorrect += (questionIsCorrect ? 1 : 0);

  }

  const numAnsPct = ((numCorrect / numAns) * 100);

  return {
    numCorrect,
    numAns,
    numAnsPct
  };
}