import { useEffect, useState } from "react";
import { Button, Col, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import Avatar from 'react-avatar';
import { useAuth0 } from "@auth0/auth0-react";

import { useRecoilState } from "recoil";
import { getUserColor } from "./user";

export const LoginModal = () => {

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup,  } = useAuth0();



  const [usernameInput, setUsernameInput] = useState('');



  return <> 
  <Modal
    show={!isAuthenticated}
    onHide={() => {}}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header>
      <Modal.Title>Eduerity - Welcome</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <Row>
    <Col md={8}><strong>Please login to continue</strong></Col>
    <Col md={4}>
      {/* <Avatar round={true} name={usernameInput} color={getUserColor(usernameInput)} />   */}
      <img src="/images/learning.svg"  />
    </Col>
    </Row>
    
    <Row>
    <Col sm={12}>
    </Col>
    </Row>

    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" size="lg" style={{width: '100%'}} onClick={() => {
      loginWithRedirect({ authorizationParams: {
        screen_hint: 'login',
        redirect_uri: window.location.origin + '/',
      }});
    }}>Login</Button>
    <Button variant="secondary" size="lg" style={{width: '100%'}} onClick={() => {
      loginWithRedirect({ authorizationParams: {
        screen_hint: 'signup',
        redirect_uri: window.location.origin + '/',
      }});
    }}>Register</Button>
    </Modal.Footer>
  </Modal>
  </>;
}