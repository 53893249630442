import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { Button, Container, Form, Nav, Navbar, Tab, Tabs } from 'react-bootstrap';
// @ts-ignore
import { useRecoilState, useResetRecoilState } from 'recoil';
import { EditQuiz } from './EditQuiz/EditQuiz';
import { LoginModal } from './LoginModal';
import { NotVerifiedModal } from './NotVerifiedModal';
import { activeQuiz, currentQuestionState, currentUserAnswersState, userAccessTokenState, userCompleteQuizState, userStartQuizState } from './state/mainstate';
import { getUserColor } from './user';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { Quiz } from './quiz-types';
import { useAuth0 } from "@auth0/auth0-react";
import { JoinByCode } from './Modals/JoinByCode';
import { getAPIPath } from './core';
import { ShareQuiz } from './Modals/ShareQuiz';

interface Props {
  joinByCode?: boolean
}

export const MainPage = (props: Props) => {

  const { loginWithRedirect, logout, user, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();


  const [quiz, setQuiz] = useRecoilState(activeQuiz);

  const [userAccessToken, setUserAccessToken] = useRecoilState(userAccessTokenState);

  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);

  const resetUserAnswers = useResetRecoilState(currentUserAnswersState);

  const resetUserStartQuiz = useResetRecoilState(userStartQuizState);
  const resetUserCompleteQuiz = useResetRecoilState(userCompleteQuizState);



  const [key, setKey] = useState('takeQuiz');


  useEffect(() => {

    const domain = "dev-p61esogm.us.auth0.com";

    const getToken = async () => {
      // const accessToken = await getAccessTokenSilently({
      //   audience: `https://${domain}/api/v2/`,
      //   scope: "read:current_user",
      // });

      if (!isAuthenticated) { return; }

      const accessToken = await getAccessTokenSilently();
      setUserAccessToken(accessToken);

      console.log('accessToken', accessToken);
      

      // 
    }


    getToken();

  }, [getAccessTokenSilently, user?.sub]);



  

  const saveQuiz = () => {
    if (quiz === null) {
      toast.error("No quiz opened, cannot save.");
      return;
    }

    const resFileName = prompt("Filename?", quiz.title);
    if (resFileName === null) {
      toast.error("Invalid filename, cannot save");
      return;
    }

    var blob = new Blob([JSON.stringify(quiz)], { type: "application/json;charset=utf-8" });
    saveAs(blob, `${resFileName}.json`);
  }

  return <>
    <div>

      {!isAuthenticated && <LoginModal />}
      
      {/* {isAuthenticated && !user!!.email_verified && <NotVerifiedModal />} */}

      <Navbar bg="primary" variant="dark" className='eduerity-navbar'>
        <Navbar.Brand href="#home">Eduerity</Navbar.Brand>
        <Nav className="mr-auto">
        </Nav>
        <Form>

          {isAuthenticated && user !== undefined && <>
            <Avatar round={true} name={user.name as string} color={getUserColor(user.name as string)} size="30"
              textSizeRatio={2}
            />
            <span style={{ marginLeft: 10, marginRight: 10, color: 'white', fontWeight: 'bold' }}>{user.name as string}</span></>
          }
          {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}

          {!isAuthenticated && <Button variant="outline-light" onClick={() => () => {
            loginWithRedirect({ authorizationParams: {
              screen_hint: 'login',
              redirect_uri: window.location.origin + '/',
            }});
          }}>Login / Sign Up</Button>}
          {isAuthenticated && <Button variant="outline-light" onClick={() => logout({
            logoutParams: {
              returnTo: window.location.origin + '/',
            }
          })}>Logout</Button>}


          {/* {login && <Button variant="outline-light" style={{ marginRight: 5 }} onClick={changeName}>Change name</Button>} */}

          {/* {isAuthenticated && <>
            <Button variant="outline-light" style={{ marginRight: 5 }}
              onClick={loadQuiz}
            ><i className="bi bi-upload"></i> Load quiz</Button>
            <Button variant="outline-light"
              onClick={saveQuiz}
            ><i className="bi bi-save"></i> Save quiz</Button>
          </>} */}


        </Form>
      </Navbar>
      <>
        {!isAuthenticated && <>
          <h1>Please login to continue.</h1>
        </>
        }
        {isAuthenticated && userAccessToken !== '' && <>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k as string)}
          >
            <Tab eventKey="takeQuiz" title="Take Quiz">
              {key === 'takeQuiz' && <>
                {/* <TakeQuiz loadQuiz={() => {}} createQuiz={createQuiz} /> */}
              </>}
            </Tab>
            <Tab eventKey="editQuiz" title="Edit Quiz">
              {key === 'editQuiz' && <EditQuiz />}
            </Tab>
          </Tabs>
        </>}
        <span style={{ color: '#828282', fontSize: 13 }}>Made by <a target="_blank" rel="noreferrer" href="https://github.com/ErlendEllingsen">Erlend Ellingsen</a>, Source code is <a target="_blank" rel="noreferrer" href="https://github.com/ErlendEllingsen/question-mgr">public</a></span>
      </>
    </div>
  </>;
}